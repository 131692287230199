import { Injectable } from '@angular/core';
import { MissingTranslationHandler, TranslateCompiler, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Language, LocalizationService, LocalizationSettings } from './localization.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@Injectable({
  providedIn: 'root'
})
export class SalesOrders_LocalizationService
  extends LocalizationService<{  }> {

  public SalesOrders: SalesOrders_LocalizationService = this;

  public override get settings(): LocalizationSettings {
    return {"default":"","languages":[]}
  }

  constructor(
    httpClient: HttpClient,
    compiler: TranslateCompiler,
    parser: TranslateParser,
    missingHandler: MissingTranslationHandler
    ) {
    super(
      new TranslateService(
        new TranslateStore(),
        new TranslateHttpLoader(httpClient, '../assets/i18n/SalesOrders/', '.json'),
        compiler,
        parser,
        missingHandler,
        true,
        true,
        false,
        ''));
    
    this.initialize();

  }

  public override setLanguage(lang: Language): void{
    super.setLanguage(lang);
  }
}