import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_packing_slip_lines_mergedService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { orderId?: number, shippingContainerId?: number }): Promise<{ result?: { Contents?: { ShippingContainerLookup?: string, MaterialLookup?: string, MaterialDescription?: string, VendorLotLookup?: string, Expiration?: string, ShippingContainerId?: number, PackedQty?: number }[] } }> {

    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_packing_slip_lines_merged/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
