import { Inject, Injectable, Injector }from '@angular/core';


import { Returns_ds_blind_shipping_container_return_tasks_gridService } from './Returns.datasource.index';
import { Returns_ds_customer_return_shipping_containers_gridService } from './Returns.datasource.index';
import { Returns_ds_find_licenseplatesService } from './Returns.datasource.index';
import { Returns_ds_find_lotsService } from './Returns.datasource.index';
import { Returns_ds_find_putaway_task_by_licenseplateIdService } from './Returns.datasource.index';
import { Returns_ds_find_serialnumbersService } from './Returns.datasource.index';
import { Returns_ds_find_tasksService } from './Returns.datasource.index';
import { Returns_ds_get_address_by_addressIdService } from './Returns.datasource.index';
import { Returns_ds_get_annotation_tasks_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_child_serial_numbers_by_serialNumberIdService } from './Returns.datasource.index';
import { Returns_ds_get_completed_load_tasks_by_licensePlateIdsService } from './Returns.datasource.index';
import { Returns_ds_get_default_licenseplate_for_return_taskService } from './Returns.datasource.index';
import { Returns_ds_get_license_plate_by_licensePlateCodeService } from './Returns.datasource.index';
import { Returns_ds_get_license_plate_by_licensePlateIdService } from './Returns.datasource.index';
import { Returns_ds_get_licenseplates_by_shipmentId_or_idsService } from './Returns.datasource.index';
import { Returns_ds_get_material_packaging_by_materialId_packagingIdService } from './Returns.datasource.index';
import { Returns_ds_get_material_status_by_statusNameService } from './Returns.datasource.index';
import { Returns_ds_get_material_statuses_by_statusIdsService } from './Returns.datasource.index';
import { Returns_ds_get_materials_by_projectIdService } from './Returns.datasource.index';
import { Returns_ds_get_order_addresses_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_order_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_order_by_orderId_detailedService } from './Returns.datasource.index';
import { Returns_ds_get_order_details_by_shipmentIdService } from './Returns.datasource.index';
import { Returns_ds_get_order_lines_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_order_validation_results_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_orders_aggregated_expectedService } from './Returns.datasource.index';
import { Returns_ds_get_orders_by_orderIdsService } from './Returns.datasource.index';
import { Returns_ds_get_outbound_shipment_licenseplatecontentsService } from './Returns.datasource.index';
import { Returns_ds_get_receiving_tasks_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_return_order_actualsService } from './Returns.datasource.index';
import { Returns_ds_get_return_order_expectedService } from './Returns.datasource.index';
import { Returns_ds_get_return_orders_by_vendorReferenceService } from './Returns.datasource.index';
import { Returns_ds_get_return_session_by_shippingContainerIdService } from './Returns.datasource.index';
import { Returns_ds_get_return_tasks_by_targetLicensePlateIdService } from './Returns.datasource.index';
import { Returns_ds_get_scanned_material_detailsService } from './Returns.datasource.index';
import { Returns_ds_get_serialnumber_by_serialNumberIdService } from './Returns.datasource.index';
import { Returns_ds_get_serialnumbers_by_licensePlateIdsService } from './Returns.datasource.index';
import { Returns_ds_get_serialnumbers_by_outboundShipmentIdService } from './Returns.datasource.index';
import { Returns_ds_get_shipmentLines_by_orderId_shipmentIdService } from './Returns.datasource.index';
import { Returns_ds_get_shipment_by_shipmentIdService } from './Returns.datasource.index';
import { Returns_ds_get_shipments_by_orderIdService } from './Returns.datasource.index';
import { Returns_ds_get_shipping_container_by_shippingContainerCodeService } from './Returns.datasource.index';
import { Returns_ds_get_shipping_container_by_shippingContainerIdService } from './Returns.datasource.index';
import { Returns_ds_get_task_by_taskIdService } from './Returns.datasource.index';
import { Returns_ds_get_tasks_by_shippingContainerIdsService } from './Returns.datasource.index';
import { Returns_ds_get_unused_licenseplatesService } from './Returns.datasource.index';
import { Returns_ds_get_vendorlots_by_materialIdService } from './Returns.datasource.index';
import { Returns_ds_get_warehouses_by_warehouseIdsService } from './Returns.datasource.index';
import { Returns_ds_license_plates_on_shipping_container_ddService } from './Returns.datasource.index';
import { Returns_ds_locations_ddService } from './Returns.datasource.index';
import { Returns_ds_lot_statuses_ddService } from './Returns.datasource.index';
import { Returns_ds_lots_ddService } from './Returns.datasource.index';
import { Returns_ds_material_packagings_ddService } from './Returns.datasource.index';
import { Returns_ds_materials_by_project_ddService } from './Returns.datasource.index';
import { Returns_ds_order_classes_ddService } from './Returns.datasource.index';
import { Returns_ds_order_statuses_ddService } from './Returns.datasource.index';
import { Returns_ds_orderline_total_amount_widgetService } from './Returns.datasource.index';
import { Returns_ds_orderline_total_gross_received_widgetService } from './Returns.datasource.index';
import { Returns_ds_orderline_total_received_widgetService } from './Returns.datasource.index';
import { Returns_ds_outbound_orders_eligible_for_return_gridService } from './Returns.datasource.index';
import { Returns_ds_owners_ddService } from './Returns.datasource.index';
import { Returns_ds_projects_ddService } from './Returns.datasource.index';
import { Returns_ds_return_asn_order_editorService } from './Returns.datasource.index';
import { Returns_ds_return_asn_order_licenseplates_gridService } from './Returns.datasource.index';
import { Returns_ds_return_asn_orderlines_gridService } from './Returns.datasource.index';
import { Returns_ds_return_license_plates_by_shippingcontainer_ddService } from './Returns.datasource.index';
import { Returns_ds_return_order_editorService } from './Returns.datasource.index';
import { Returns_ds_return_order_lines_gridService } from './Returns.datasource.index';
import { Returns_ds_return_orders_details_gridService } from './Returns.datasource.index';
import { Returns_ds_return_orders_gridService } from './Returns.datasource.index';
import { Returns_ds_return_orders_grid_propertiesService } from './Returns.datasource.index';
import { Returns_ds_return_orders_summary_reportService } from './Returns.datasource.index';
import { Returns_ds_return_shipping_containers_gridService } from './Returns.datasource.index';
import { Returns_ds_return_shipping_containers_grid_testingService } from './Returns.datasource.index';
import { Returns_ds_vendorlots_ddService } from './Returns.datasource.index';
import { Returns_ds_warehouses_ddService } from './Returns.datasource.index';

@Injectable({ providedIn: 'root' })
export class Returns_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public Returns: Returns_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_blind_shipping_container_return_tasks_grid: Returns_ds_blind_shipping_container_return_tasks_gridService;
  public get ds_blind_shipping_container_return_tasks_grid(): Returns_ds_blind_shipping_container_return_tasks_gridService {
    if(!this._ds_blind_shipping_container_return_tasks_grid) {
      this._ds_blind_shipping_container_return_tasks_grid = this.injector.get(Returns_ds_blind_shipping_container_return_tasks_gridService);
    }
    return this._ds_blind_shipping_container_return_tasks_grid;
  }
  private _ds_customer_return_shipping_containers_grid: Returns_ds_customer_return_shipping_containers_gridService;
  public get ds_customer_return_shipping_containers_grid(): Returns_ds_customer_return_shipping_containers_gridService {
    if(!this._ds_customer_return_shipping_containers_grid) {
      this._ds_customer_return_shipping_containers_grid = this.injector.get(Returns_ds_customer_return_shipping_containers_gridService);
    }
    return this._ds_customer_return_shipping_containers_grid;
  }
  private _ds_find_licenseplates: Returns_ds_find_licenseplatesService;
  public get ds_find_licenseplates(): Returns_ds_find_licenseplatesService {
    if(!this._ds_find_licenseplates) {
      this._ds_find_licenseplates = this.injector.get(Returns_ds_find_licenseplatesService);
    }
    return this._ds_find_licenseplates;
  }
  private _ds_find_lots: Returns_ds_find_lotsService;
  public get ds_find_lots(): Returns_ds_find_lotsService {
    if(!this._ds_find_lots) {
      this._ds_find_lots = this.injector.get(Returns_ds_find_lotsService);
    }
    return this._ds_find_lots;
  }
  private _ds_find_putaway_task_by_licenseplateId: Returns_ds_find_putaway_task_by_licenseplateIdService;
  public get ds_find_putaway_task_by_licenseplateId(): Returns_ds_find_putaway_task_by_licenseplateIdService {
    if(!this._ds_find_putaway_task_by_licenseplateId) {
      this._ds_find_putaway_task_by_licenseplateId = this.injector.get(Returns_ds_find_putaway_task_by_licenseplateIdService);
    }
    return this._ds_find_putaway_task_by_licenseplateId;
  }
  private _ds_find_serialnumbers: Returns_ds_find_serialnumbersService;
  public get ds_find_serialnumbers(): Returns_ds_find_serialnumbersService {
    if(!this._ds_find_serialnumbers) {
      this._ds_find_serialnumbers = this.injector.get(Returns_ds_find_serialnumbersService);
    }
    return this._ds_find_serialnumbers;
  }
  private _ds_find_tasks: Returns_ds_find_tasksService;
  public get ds_find_tasks(): Returns_ds_find_tasksService {
    if(!this._ds_find_tasks) {
      this._ds_find_tasks = this.injector.get(Returns_ds_find_tasksService);
    }
    return this._ds_find_tasks;
  }
  private _ds_get_address_by_addressId: Returns_ds_get_address_by_addressIdService;
  public get ds_get_address_by_addressId(): Returns_ds_get_address_by_addressIdService {
    if(!this._ds_get_address_by_addressId) {
      this._ds_get_address_by_addressId = this.injector.get(Returns_ds_get_address_by_addressIdService);
    }
    return this._ds_get_address_by_addressId;
  }
  private _ds_get_annotation_tasks_by_orderId: Returns_ds_get_annotation_tasks_by_orderIdService;
  public get ds_get_annotation_tasks_by_orderId(): Returns_ds_get_annotation_tasks_by_orderIdService {
    if(!this._ds_get_annotation_tasks_by_orderId) {
      this._ds_get_annotation_tasks_by_orderId = this.injector.get(Returns_ds_get_annotation_tasks_by_orderIdService);
    }
    return this._ds_get_annotation_tasks_by_orderId;
  }
  private _ds_get_child_serial_numbers_by_serialNumberId: Returns_ds_get_child_serial_numbers_by_serialNumberIdService;
  public get ds_get_child_serial_numbers_by_serialNumberId(): Returns_ds_get_child_serial_numbers_by_serialNumberIdService {
    if(!this._ds_get_child_serial_numbers_by_serialNumberId) {
      this._ds_get_child_serial_numbers_by_serialNumberId = this.injector.get(Returns_ds_get_child_serial_numbers_by_serialNumberIdService);
    }
    return this._ds_get_child_serial_numbers_by_serialNumberId;
  }
  private _ds_get_completed_load_tasks_by_licensePlateIds: Returns_ds_get_completed_load_tasks_by_licensePlateIdsService;
  public get ds_get_completed_load_tasks_by_licensePlateIds(): Returns_ds_get_completed_load_tasks_by_licensePlateIdsService {
    if(!this._ds_get_completed_load_tasks_by_licensePlateIds) {
      this._ds_get_completed_load_tasks_by_licensePlateIds = this.injector.get(Returns_ds_get_completed_load_tasks_by_licensePlateIdsService);
    }
    return this._ds_get_completed_load_tasks_by_licensePlateIds;
  }
  private _ds_get_default_licenseplate_for_return_task: Returns_ds_get_default_licenseplate_for_return_taskService;
  public get ds_get_default_licenseplate_for_return_task(): Returns_ds_get_default_licenseplate_for_return_taskService {
    if(!this._ds_get_default_licenseplate_for_return_task) {
      this._ds_get_default_licenseplate_for_return_task = this.injector.get(Returns_ds_get_default_licenseplate_for_return_taskService);
    }
    return this._ds_get_default_licenseplate_for_return_task;
  }
  private _ds_get_license_plate_by_licensePlateCode: Returns_ds_get_license_plate_by_licensePlateCodeService;
  public get ds_get_license_plate_by_licensePlateCode(): Returns_ds_get_license_plate_by_licensePlateCodeService {
    if(!this._ds_get_license_plate_by_licensePlateCode) {
      this._ds_get_license_plate_by_licensePlateCode = this.injector.get(Returns_ds_get_license_plate_by_licensePlateCodeService);
    }
    return this._ds_get_license_plate_by_licensePlateCode;
  }
  private _ds_get_license_plate_by_licensePlateId: Returns_ds_get_license_plate_by_licensePlateIdService;
  public get ds_get_license_plate_by_licensePlateId(): Returns_ds_get_license_plate_by_licensePlateIdService {
    if(!this._ds_get_license_plate_by_licensePlateId) {
      this._ds_get_license_plate_by_licensePlateId = this.injector.get(Returns_ds_get_license_plate_by_licensePlateIdService);
    }
    return this._ds_get_license_plate_by_licensePlateId;
  }
  private _ds_get_licenseplates_by_shipmentId_or_ids: Returns_ds_get_licenseplates_by_shipmentId_or_idsService;
  public get ds_get_licenseplates_by_shipmentId_or_ids(): Returns_ds_get_licenseplates_by_shipmentId_or_idsService {
    if(!this._ds_get_licenseplates_by_shipmentId_or_ids) {
      this._ds_get_licenseplates_by_shipmentId_or_ids = this.injector.get(Returns_ds_get_licenseplates_by_shipmentId_or_idsService);
    }
    return this._ds_get_licenseplates_by_shipmentId_or_ids;
  }
  private _ds_get_material_packaging_by_materialId_packagingId: Returns_ds_get_material_packaging_by_materialId_packagingIdService;
  public get ds_get_material_packaging_by_materialId_packagingId(): Returns_ds_get_material_packaging_by_materialId_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_packagingId) {
      this._ds_get_material_packaging_by_materialId_packagingId = this.injector.get(Returns_ds_get_material_packaging_by_materialId_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_packagingId;
  }
  private _ds_get_material_status_by_statusName: Returns_ds_get_material_status_by_statusNameService;
  public get ds_get_material_status_by_statusName(): Returns_ds_get_material_status_by_statusNameService {
    if(!this._ds_get_material_status_by_statusName) {
      this._ds_get_material_status_by_statusName = this.injector.get(Returns_ds_get_material_status_by_statusNameService);
    }
    return this._ds_get_material_status_by_statusName;
  }
  private _ds_get_material_statuses_by_statusIds: Returns_ds_get_material_statuses_by_statusIdsService;
  public get ds_get_material_statuses_by_statusIds(): Returns_ds_get_material_statuses_by_statusIdsService {
    if(!this._ds_get_material_statuses_by_statusIds) {
      this._ds_get_material_statuses_by_statusIds = this.injector.get(Returns_ds_get_material_statuses_by_statusIdsService);
    }
    return this._ds_get_material_statuses_by_statusIds;
  }
  private _ds_get_materials_by_projectId: Returns_ds_get_materials_by_projectIdService;
  public get ds_get_materials_by_projectId(): Returns_ds_get_materials_by_projectIdService {
    if(!this._ds_get_materials_by_projectId) {
      this._ds_get_materials_by_projectId = this.injector.get(Returns_ds_get_materials_by_projectIdService);
    }
    return this._ds_get_materials_by_projectId;
  }
  private _ds_get_order_addresses_by_orderId: Returns_ds_get_order_addresses_by_orderIdService;
  public get ds_get_order_addresses_by_orderId(): Returns_ds_get_order_addresses_by_orderIdService {
    if(!this._ds_get_order_addresses_by_orderId) {
      this._ds_get_order_addresses_by_orderId = this.injector.get(Returns_ds_get_order_addresses_by_orderIdService);
    }
    return this._ds_get_order_addresses_by_orderId;
  }
  private _ds_get_order_by_orderId: Returns_ds_get_order_by_orderIdService;
  public get ds_get_order_by_orderId(): Returns_ds_get_order_by_orderIdService {
    if(!this._ds_get_order_by_orderId) {
      this._ds_get_order_by_orderId = this.injector.get(Returns_ds_get_order_by_orderIdService);
    }
    return this._ds_get_order_by_orderId;
  }
  private _ds_get_order_by_orderId_detailed: Returns_ds_get_order_by_orderId_detailedService;
  public get ds_get_order_by_orderId_detailed(): Returns_ds_get_order_by_orderId_detailedService {
    if(!this._ds_get_order_by_orderId_detailed) {
      this._ds_get_order_by_orderId_detailed = this.injector.get(Returns_ds_get_order_by_orderId_detailedService);
    }
    return this._ds_get_order_by_orderId_detailed;
  }
  private _ds_get_order_details_by_shipmentId: Returns_ds_get_order_details_by_shipmentIdService;
  public get ds_get_order_details_by_shipmentId(): Returns_ds_get_order_details_by_shipmentIdService {
    if(!this._ds_get_order_details_by_shipmentId) {
      this._ds_get_order_details_by_shipmentId = this.injector.get(Returns_ds_get_order_details_by_shipmentIdService);
    }
    return this._ds_get_order_details_by_shipmentId;
  }
  private _ds_get_order_lines_by_orderId: Returns_ds_get_order_lines_by_orderIdService;
  public get ds_get_order_lines_by_orderId(): Returns_ds_get_order_lines_by_orderIdService {
    if(!this._ds_get_order_lines_by_orderId) {
      this._ds_get_order_lines_by_orderId = this.injector.get(Returns_ds_get_order_lines_by_orderIdService);
    }
    return this._ds_get_order_lines_by_orderId;
  }
  private _ds_get_order_validation_results_by_orderId: Returns_ds_get_order_validation_results_by_orderIdService;
  public get ds_get_order_validation_results_by_orderId(): Returns_ds_get_order_validation_results_by_orderIdService {
    if(!this._ds_get_order_validation_results_by_orderId) {
      this._ds_get_order_validation_results_by_orderId = this.injector.get(Returns_ds_get_order_validation_results_by_orderIdService);
    }
    return this._ds_get_order_validation_results_by_orderId;
  }
  private _ds_get_orders_aggregated_expected: Returns_ds_get_orders_aggregated_expectedService;
  public get ds_get_orders_aggregated_expected(): Returns_ds_get_orders_aggregated_expectedService {
    if(!this._ds_get_orders_aggregated_expected) {
      this._ds_get_orders_aggregated_expected = this.injector.get(Returns_ds_get_orders_aggregated_expectedService);
    }
    return this._ds_get_orders_aggregated_expected;
  }
  private _ds_get_orders_by_orderIds: Returns_ds_get_orders_by_orderIdsService;
  public get ds_get_orders_by_orderIds(): Returns_ds_get_orders_by_orderIdsService {
    if(!this._ds_get_orders_by_orderIds) {
      this._ds_get_orders_by_orderIds = this.injector.get(Returns_ds_get_orders_by_orderIdsService);
    }
    return this._ds_get_orders_by_orderIds;
  }
  private _ds_get_outbound_shipment_licenseplatecontents: Returns_ds_get_outbound_shipment_licenseplatecontentsService;
  public get ds_get_outbound_shipment_licenseplatecontents(): Returns_ds_get_outbound_shipment_licenseplatecontentsService {
    if(!this._ds_get_outbound_shipment_licenseplatecontents) {
      this._ds_get_outbound_shipment_licenseplatecontents = this.injector.get(Returns_ds_get_outbound_shipment_licenseplatecontentsService);
    }
    return this._ds_get_outbound_shipment_licenseplatecontents;
  }
  private _ds_get_receiving_tasks_by_orderId: Returns_ds_get_receiving_tasks_by_orderIdService;
  public get ds_get_receiving_tasks_by_orderId(): Returns_ds_get_receiving_tasks_by_orderIdService {
    if(!this._ds_get_receiving_tasks_by_orderId) {
      this._ds_get_receiving_tasks_by_orderId = this.injector.get(Returns_ds_get_receiving_tasks_by_orderIdService);
    }
    return this._ds_get_receiving_tasks_by_orderId;
  }
  private _ds_get_return_order_actuals: Returns_ds_get_return_order_actualsService;
  public get ds_get_return_order_actuals(): Returns_ds_get_return_order_actualsService {
    if(!this._ds_get_return_order_actuals) {
      this._ds_get_return_order_actuals = this.injector.get(Returns_ds_get_return_order_actualsService);
    }
    return this._ds_get_return_order_actuals;
  }
  private _ds_get_return_order_expected: Returns_ds_get_return_order_expectedService;
  public get ds_get_return_order_expected(): Returns_ds_get_return_order_expectedService {
    if(!this._ds_get_return_order_expected) {
      this._ds_get_return_order_expected = this.injector.get(Returns_ds_get_return_order_expectedService);
    }
    return this._ds_get_return_order_expected;
  }
  private _ds_get_return_orders_by_vendorReference: Returns_ds_get_return_orders_by_vendorReferenceService;
  public get ds_get_return_orders_by_vendorReference(): Returns_ds_get_return_orders_by_vendorReferenceService {
    if(!this._ds_get_return_orders_by_vendorReference) {
      this._ds_get_return_orders_by_vendorReference = this.injector.get(Returns_ds_get_return_orders_by_vendorReferenceService);
    }
    return this._ds_get_return_orders_by_vendorReference;
  }
  private _ds_get_return_session_by_shippingContainerId: Returns_ds_get_return_session_by_shippingContainerIdService;
  public get ds_get_return_session_by_shippingContainerId(): Returns_ds_get_return_session_by_shippingContainerIdService {
    if(!this._ds_get_return_session_by_shippingContainerId) {
      this._ds_get_return_session_by_shippingContainerId = this.injector.get(Returns_ds_get_return_session_by_shippingContainerIdService);
    }
    return this._ds_get_return_session_by_shippingContainerId;
  }
  private _ds_get_return_tasks_by_targetLicensePlateId: Returns_ds_get_return_tasks_by_targetLicensePlateIdService;
  public get ds_get_return_tasks_by_targetLicensePlateId(): Returns_ds_get_return_tasks_by_targetLicensePlateIdService {
    if(!this._ds_get_return_tasks_by_targetLicensePlateId) {
      this._ds_get_return_tasks_by_targetLicensePlateId = this.injector.get(Returns_ds_get_return_tasks_by_targetLicensePlateIdService);
    }
    return this._ds_get_return_tasks_by_targetLicensePlateId;
  }
  private _ds_get_scanned_material_details: Returns_ds_get_scanned_material_detailsService;
  public get ds_get_scanned_material_details(): Returns_ds_get_scanned_material_detailsService {
    if(!this._ds_get_scanned_material_details) {
      this._ds_get_scanned_material_details = this.injector.get(Returns_ds_get_scanned_material_detailsService);
    }
    return this._ds_get_scanned_material_details;
  }
  private _ds_get_serialnumber_by_serialNumberId: Returns_ds_get_serialnumber_by_serialNumberIdService;
  public get ds_get_serialnumber_by_serialNumberId(): Returns_ds_get_serialnumber_by_serialNumberIdService {
    if(!this._ds_get_serialnumber_by_serialNumberId) {
      this._ds_get_serialnumber_by_serialNumberId = this.injector.get(Returns_ds_get_serialnumber_by_serialNumberIdService);
    }
    return this._ds_get_serialnumber_by_serialNumberId;
  }
  private _ds_get_serialnumbers_by_licensePlateIds: Returns_ds_get_serialnumbers_by_licensePlateIdsService;
  public get ds_get_serialnumbers_by_licensePlateIds(): Returns_ds_get_serialnumbers_by_licensePlateIdsService {
    if(!this._ds_get_serialnumbers_by_licensePlateIds) {
      this._ds_get_serialnumbers_by_licensePlateIds = this.injector.get(Returns_ds_get_serialnumbers_by_licensePlateIdsService);
    }
    return this._ds_get_serialnumbers_by_licensePlateIds;
  }
  private _ds_get_serialnumbers_by_outboundShipmentId: Returns_ds_get_serialnumbers_by_outboundShipmentIdService;
  public get ds_get_serialnumbers_by_outboundShipmentId(): Returns_ds_get_serialnumbers_by_outboundShipmentIdService {
    if(!this._ds_get_serialnumbers_by_outboundShipmentId) {
      this._ds_get_serialnumbers_by_outboundShipmentId = this.injector.get(Returns_ds_get_serialnumbers_by_outboundShipmentIdService);
    }
    return this._ds_get_serialnumbers_by_outboundShipmentId;
  }
  private _ds_get_shipmentLines_by_orderId_shipmentId: Returns_ds_get_shipmentLines_by_orderId_shipmentIdService;
  public get ds_get_shipmentLines_by_orderId_shipmentId(): Returns_ds_get_shipmentLines_by_orderId_shipmentIdService {
    if(!this._ds_get_shipmentLines_by_orderId_shipmentId) {
      this._ds_get_shipmentLines_by_orderId_shipmentId = this.injector.get(Returns_ds_get_shipmentLines_by_orderId_shipmentIdService);
    }
    return this._ds_get_shipmentLines_by_orderId_shipmentId;
  }
  private _ds_get_shipment_by_shipmentId: Returns_ds_get_shipment_by_shipmentIdService;
  public get ds_get_shipment_by_shipmentId(): Returns_ds_get_shipment_by_shipmentIdService {
    if(!this._ds_get_shipment_by_shipmentId) {
      this._ds_get_shipment_by_shipmentId = this.injector.get(Returns_ds_get_shipment_by_shipmentIdService);
    }
    return this._ds_get_shipment_by_shipmentId;
  }
  private _ds_get_shipments_by_orderId: Returns_ds_get_shipments_by_orderIdService;
  public get ds_get_shipments_by_orderId(): Returns_ds_get_shipments_by_orderIdService {
    if(!this._ds_get_shipments_by_orderId) {
      this._ds_get_shipments_by_orderId = this.injector.get(Returns_ds_get_shipments_by_orderIdService);
    }
    return this._ds_get_shipments_by_orderId;
  }
  private _ds_get_shipping_container_by_shippingContainerCode: Returns_ds_get_shipping_container_by_shippingContainerCodeService;
  public get ds_get_shipping_container_by_shippingContainerCode(): Returns_ds_get_shipping_container_by_shippingContainerCodeService {
    if(!this._ds_get_shipping_container_by_shippingContainerCode) {
      this._ds_get_shipping_container_by_shippingContainerCode = this.injector.get(Returns_ds_get_shipping_container_by_shippingContainerCodeService);
    }
    return this._ds_get_shipping_container_by_shippingContainerCode;
  }
  private _ds_get_shipping_container_by_shippingContainerId: Returns_ds_get_shipping_container_by_shippingContainerIdService;
  public get ds_get_shipping_container_by_shippingContainerId(): Returns_ds_get_shipping_container_by_shippingContainerIdService {
    if(!this._ds_get_shipping_container_by_shippingContainerId) {
      this._ds_get_shipping_container_by_shippingContainerId = this.injector.get(Returns_ds_get_shipping_container_by_shippingContainerIdService);
    }
    return this._ds_get_shipping_container_by_shippingContainerId;
  }
  private _ds_get_task_by_taskId: Returns_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): Returns_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(Returns_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_tasks_by_shippingContainerIds: Returns_ds_get_tasks_by_shippingContainerIdsService;
  public get ds_get_tasks_by_shippingContainerIds(): Returns_ds_get_tasks_by_shippingContainerIdsService {
    if(!this._ds_get_tasks_by_shippingContainerIds) {
      this._ds_get_tasks_by_shippingContainerIds = this.injector.get(Returns_ds_get_tasks_by_shippingContainerIdsService);
    }
    return this._ds_get_tasks_by_shippingContainerIds;
  }
  private _ds_get_unused_licenseplates: Returns_ds_get_unused_licenseplatesService;
  public get ds_get_unused_licenseplates(): Returns_ds_get_unused_licenseplatesService {
    if(!this._ds_get_unused_licenseplates) {
      this._ds_get_unused_licenseplates = this.injector.get(Returns_ds_get_unused_licenseplatesService);
    }
    return this._ds_get_unused_licenseplates;
  }
  private _ds_get_vendorlots_by_materialId: Returns_ds_get_vendorlots_by_materialIdService;
  public get ds_get_vendorlots_by_materialId(): Returns_ds_get_vendorlots_by_materialIdService {
    if(!this._ds_get_vendorlots_by_materialId) {
      this._ds_get_vendorlots_by_materialId = this.injector.get(Returns_ds_get_vendorlots_by_materialIdService);
    }
    return this._ds_get_vendorlots_by_materialId;
  }
  private _ds_get_warehouses_by_warehouseIds: Returns_ds_get_warehouses_by_warehouseIdsService;
  public get ds_get_warehouses_by_warehouseIds(): Returns_ds_get_warehouses_by_warehouseIdsService {
    if(!this._ds_get_warehouses_by_warehouseIds) {
      this._ds_get_warehouses_by_warehouseIds = this.injector.get(Returns_ds_get_warehouses_by_warehouseIdsService);
    }
    return this._ds_get_warehouses_by_warehouseIds;
  }
  private _ds_license_plates_on_shipping_container_dd: Returns_ds_license_plates_on_shipping_container_ddService;
  public get ds_license_plates_on_shipping_container_dd(): Returns_ds_license_plates_on_shipping_container_ddService {
    if(!this._ds_license_plates_on_shipping_container_dd) {
      this._ds_license_plates_on_shipping_container_dd = this.injector.get(Returns_ds_license_plates_on_shipping_container_ddService);
    }
    return this._ds_license_plates_on_shipping_container_dd;
  }
  private _ds_locations_dd: Returns_ds_locations_ddService;
  public get ds_locations_dd(): Returns_ds_locations_ddService {
    if(!this._ds_locations_dd) {
      this._ds_locations_dd = this.injector.get(Returns_ds_locations_ddService);
    }
    return this._ds_locations_dd;
  }
  private _ds_lot_statuses_dd: Returns_ds_lot_statuses_ddService;
  public get ds_lot_statuses_dd(): Returns_ds_lot_statuses_ddService {
    if(!this._ds_lot_statuses_dd) {
      this._ds_lot_statuses_dd = this.injector.get(Returns_ds_lot_statuses_ddService);
    }
    return this._ds_lot_statuses_dd;
  }
  private _ds_lots_dd: Returns_ds_lots_ddService;
  public get ds_lots_dd(): Returns_ds_lots_ddService {
    if(!this._ds_lots_dd) {
      this._ds_lots_dd = this.injector.get(Returns_ds_lots_ddService);
    }
    return this._ds_lots_dd;
  }
  private _ds_material_packagings_dd: Returns_ds_material_packagings_ddService;
  public get ds_material_packagings_dd(): Returns_ds_material_packagings_ddService {
    if(!this._ds_material_packagings_dd) {
      this._ds_material_packagings_dd = this.injector.get(Returns_ds_material_packagings_ddService);
    }
    return this._ds_material_packagings_dd;
  }
  private _ds_materials_by_project_dd: Returns_ds_materials_by_project_ddService;
  public get ds_materials_by_project_dd(): Returns_ds_materials_by_project_ddService {
    if(!this._ds_materials_by_project_dd) {
      this._ds_materials_by_project_dd = this.injector.get(Returns_ds_materials_by_project_ddService);
    }
    return this._ds_materials_by_project_dd;
  }
  private _ds_order_classes_dd: Returns_ds_order_classes_ddService;
  public get ds_order_classes_dd(): Returns_ds_order_classes_ddService {
    if(!this._ds_order_classes_dd) {
      this._ds_order_classes_dd = this.injector.get(Returns_ds_order_classes_ddService);
    }
    return this._ds_order_classes_dd;
  }
  private _ds_order_statuses_dd: Returns_ds_order_statuses_ddService;
  public get ds_order_statuses_dd(): Returns_ds_order_statuses_ddService {
    if(!this._ds_order_statuses_dd) {
      this._ds_order_statuses_dd = this.injector.get(Returns_ds_order_statuses_ddService);
    }
    return this._ds_order_statuses_dd;
  }
  private _ds_orderline_total_amount_widget: Returns_ds_orderline_total_amount_widgetService;
  public get ds_orderline_total_amount_widget(): Returns_ds_orderline_total_amount_widgetService {
    if(!this._ds_orderline_total_amount_widget) {
      this._ds_orderline_total_amount_widget = this.injector.get(Returns_ds_orderline_total_amount_widgetService);
    }
    return this._ds_orderline_total_amount_widget;
  }
  private _ds_orderline_total_gross_received_widget: Returns_ds_orderline_total_gross_received_widgetService;
  public get ds_orderline_total_gross_received_widget(): Returns_ds_orderline_total_gross_received_widgetService {
    if(!this._ds_orderline_total_gross_received_widget) {
      this._ds_orderline_total_gross_received_widget = this.injector.get(Returns_ds_orderline_total_gross_received_widgetService);
    }
    return this._ds_orderline_total_gross_received_widget;
  }
  private _ds_orderline_total_received_widget: Returns_ds_orderline_total_received_widgetService;
  public get ds_orderline_total_received_widget(): Returns_ds_orderline_total_received_widgetService {
    if(!this._ds_orderline_total_received_widget) {
      this._ds_orderline_total_received_widget = this.injector.get(Returns_ds_orderline_total_received_widgetService);
    }
    return this._ds_orderline_total_received_widget;
  }
  private _ds_outbound_orders_eligible_for_return_grid: Returns_ds_outbound_orders_eligible_for_return_gridService;
  public get ds_outbound_orders_eligible_for_return_grid(): Returns_ds_outbound_orders_eligible_for_return_gridService {
    if(!this._ds_outbound_orders_eligible_for_return_grid) {
      this._ds_outbound_orders_eligible_for_return_grid = this.injector.get(Returns_ds_outbound_orders_eligible_for_return_gridService);
    }
    return this._ds_outbound_orders_eligible_for_return_grid;
  }
  private _ds_owners_dd: Returns_ds_owners_ddService;
  public get ds_owners_dd(): Returns_ds_owners_ddService {
    if(!this._ds_owners_dd) {
      this._ds_owners_dd = this.injector.get(Returns_ds_owners_ddService);
    }
    return this._ds_owners_dd;
  }
  private _ds_projects_dd: Returns_ds_projects_ddService;
  public get ds_projects_dd(): Returns_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(Returns_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_return_asn_order_editor: Returns_ds_return_asn_order_editorService;
  public get ds_return_asn_order_editor(): Returns_ds_return_asn_order_editorService {
    if(!this._ds_return_asn_order_editor) {
      this._ds_return_asn_order_editor = this.injector.get(Returns_ds_return_asn_order_editorService);
    }
    return this._ds_return_asn_order_editor;
  }
  private _ds_return_asn_order_licenseplates_grid: Returns_ds_return_asn_order_licenseplates_gridService;
  public get ds_return_asn_order_licenseplates_grid(): Returns_ds_return_asn_order_licenseplates_gridService {
    if(!this._ds_return_asn_order_licenseplates_grid) {
      this._ds_return_asn_order_licenseplates_grid = this.injector.get(Returns_ds_return_asn_order_licenseplates_gridService);
    }
    return this._ds_return_asn_order_licenseplates_grid;
  }
  private _ds_return_asn_orderlines_grid: Returns_ds_return_asn_orderlines_gridService;
  public get ds_return_asn_orderlines_grid(): Returns_ds_return_asn_orderlines_gridService {
    if(!this._ds_return_asn_orderlines_grid) {
      this._ds_return_asn_orderlines_grid = this.injector.get(Returns_ds_return_asn_orderlines_gridService);
    }
    return this._ds_return_asn_orderlines_grid;
  }
  private _ds_return_license_plates_by_shippingcontainer_dd: Returns_ds_return_license_plates_by_shippingcontainer_ddService;
  public get ds_return_license_plates_by_shippingcontainer_dd(): Returns_ds_return_license_plates_by_shippingcontainer_ddService {
    if(!this._ds_return_license_plates_by_shippingcontainer_dd) {
      this._ds_return_license_plates_by_shippingcontainer_dd = this.injector.get(Returns_ds_return_license_plates_by_shippingcontainer_ddService);
    }
    return this._ds_return_license_plates_by_shippingcontainer_dd;
  }
  private _ds_return_order_editor: Returns_ds_return_order_editorService;
  public get ds_return_order_editor(): Returns_ds_return_order_editorService {
    if(!this._ds_return_order_editor) {
      this._ds_return_order_editor = this.injector.get(Returns_ds_return_order_editorService);
    }
    return this._ds_return_order_editor;
  }
  private _ds_return_order_lines_grid: Returns_ds_return_order_lines_gridService;
  public get ds_return_order_lines_grid(): Returns_ds_return_order_lines_gridService {
    if(!this._ds_return_order_lines_grid) {
      this._ds_return_order_lines_grid = this.injector.get(Returns_ds_return_order_lines_gridService);
    }
    return this._ds_return_order_lines_grid;
  }
  private _ds_return_orders_details_grid: Returns_ds_return_orders_details_gridService;
  public get ds_return_orders_details_grid(): Returns_ds_return_orders_details_gridService {
    if(!this._ds_return_orders_details_grid) {
      this._ds_return_orders_details_grid = this.injector.get(Returns_ds_return_orders_details_gridService);
    }
    return this._ds_return_orders_details_grid;
  }
  private _ds_return_orders_grid: Returns_ds_return_orders_gridService;
  public get ds_return_orders_grid(): Returns_ds_return_orders_gridService {
    if(!this._ds_return_orders_grid) {
      this._ds_return_orders_grid = this.injector.get(Returns_ds_return_orders_gridService);
    }
    return this._ds_return_orders_grid;
  }
  private _ds_return_orders_grid_properties: Returns_ds_return_orders_grid_propertiesService;
  public get ds_return_orders_grid_properties(): Returns_ds_return_orders_grid_propertiesService {
    if(!this._ds_return_orders_grid_properties) {
      this._ds_return_orders_grid_properties = this.injector.get(Returns_ds_return_orders_grid_propertiesService);
    }
    return this._ds_return_orders_grid_properties;
  }
  private _ds_return_orders_summary_report: Returns_ds_return_orders_summary_reportService;
  public get ds_return_orders_summary_report(): Returns_ds_return_orders_summary_reportService {
    if(!this._ds_return_orders_summary_report) {
      this._ds_return_orders_summary_report = this.injector.get(Returns_ds_return_orders_summary_reportService);
    }
    return this._ds_return_orders_summary_report;
  }
  private _ds_return_shipping_containers_grid: Returns_ds_return_shipping_containers_gridService;
  public get ds_return_shipping_containers_grid(): Returns_ds_return_shipping_containers_gridService {
    if(!this._ds_return_shipping_containers_grid) {
      this._ds_return_shipping_containers_grid = this.injector.get(Returns_ds_return_shipping_containers_gridService);
    }
    return this._ds_return_shipping_containers_grid;
  }
  private _ds_return_shipping_containers_grid_testing: Returns_ds_return_shipping_containers_grid_testingService;
  public get ds_return_shipping_containers_grid_testing(): Returns_ds_return_shipping_containers_grid_testingService {
    if(!this._ds_return_shipping_containers_grid_testing) {
      this._ds_return_shipping_containers_grid_testing = this.injector.get(Returns_ds_return_shipping_containers_grid_testingService);
    }
    return this._ds_return_shipping_containers_grid_testing;
  }
  private _ds_vendorlots_dd: Returns_ds_vendorlots_ddService;
  public get ds_vendorlots_dd(): Returns_ds_vendorlots_ddService {
    if(!this._ds_vendorlots_dd) {
      this._ds_vendorlots_dd = this.injector.get(Returns_ds_vendorlots_ddService);
    }
    return this._ds_vendorlots_dd;
  }
  private _ds_warehouses_dd: Returns_ds_warehouses_ddService;
  public get ds_warehouses_dd(): Returns_ds_warehouses_ddService {
    if(!this._ds_warehouses_dd) {
      this._ds_warehouses_dd = this.injector.get(Returns_ds_warehouses_ddService);
    }
    return this._ds_warehouses_dd;
  }
}

