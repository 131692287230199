import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_transaction_reportServiceInParams {
  notes: string}

interface IReports_custom_transaction_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_transaction_reportService extends ReportBaseService<IReports_custom_transaction_reportServiceInParams, IReports_custom_transaction_reportServiceData> {

  protected reportReferenceName = 'custom_transaction_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_transaction_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_transaction_reportServiceInParams): Promise<IReports_custom_transaction_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_transaction_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_transaction_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
