import { 
  Component, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  EmbeddedViewRef,
} from '@angular/core';

import { 
  Router,
  ActivatedRoute, 
  ParamMap 
} from '@angular/router';

import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Title} from "@angular/platform-browser";

import { 
  MsalInterceptorConfiguration, 
  MsalService, 
  MSAL_INTERCEPTOR_CONFIG 
} from '@azure/msal-angular';


import { 
  AccountInfo, 
  EndSessionRequest 
} from '@azure/msal-browser';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { isNil, isObject } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexErrorService } from './error/datex-error-service';
import { protectedResources } from './auth-config';
import { ShellService } from './shell.service';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


interface IMenubarItem {
  id: string;
  icon: string;
  label: string;
  parent?: IMenubarItem;
  items?: IMenubarItem[],
  menubar?: any;
  hidden?: boolean;
  click?: ($utils: UtilsService) => void;
  onChildHidden?: (item: IMenubarItem) => void;
  onParentHidden?: (item: IMenubarItem) => void;
  getOwnHidden?(): boolean;
}

class MenuItemModel implements IMenubarItem {
  id: string;
  icon: string;
  label: string;
  parent?: IMenubarItem;
  items: IMenubarItem[] = [];
  menubar: any;
  click?: ($utils: UtilsService) => void;
  private _hidden: boolean = false;
  private _onMenuHidden: () => void;

  constructor(id: string, icon: string, label: string, items: IMenubarItem[], click: ($utils: UtilsService) => void, onMenuHidden: () => void) {
    this.id = id;
    this.icon = icon;
    this.label = label;
    this.items = items;
    this.items?.forEach(i => i.parent = this);
    this.menubar = items ? Object.fromEntries(items.map(i => [i.id, i]) ?? []) : null;
    this.click = click;
    this._onMenuHidden = onMenuHidden;
  }

  onParentHidden(item: IMenubarItem){
    this._hidden = item.hidden;
    if (this._hidden) {
      this._onMenuHidden();
    }
  }

  onChildHidden(item: IMenubarItem){
    this._hidden = this.items.every(i => i.getOwnHidden());
    if (this._hidden) {
      this._onMenuHidden();
    }
  }

  get hidden(): boolean{
    return this._hidden || this.parent?.hidden || false;
  }

  set hidden(val: boolean){
    this._hidden = val;
    this.parent?.onChildHidden(this);
    this.items?.forEach(i => i.onParentHidden(this));
  }

  getOwnHidden(): boolean {
    return this._hidden;
  }
}

class Crumb {
  destroyed$ = new Subject();

  title: string;
  referenceName: string;
  active: boolean;
  queryString: string;

  componentRef: ComponentRef<any>;
  closeToolRef: EmbeddedViewRef<any>;

  constructor(
    title: string,
    referenceName: string,
    component: ComponentRef<any>,
    closeTool: EmbeddedViewRef<any>,
    inParams: any
  ) {
    this.title = title;
    this.referenceName = referenceName;
    this.componentRef = component;
    this.closeToolRef = closeTool;

    if (inParams) {
      this.queryString = Object.keys(inParams).map((key) => {
        const paramValue = inParams[key];
        // Will stringify if obj or array
        if (isObject(paramValue)) {
          return `${key}=${encodeURIComponent(JSON.stringify(paramValue))}`;
        } else {
          return `${key}=${encodeURIComponent(paramValue)}`;
        }
      }).join('&');
    }
  }

  destroy() {
    this.componentRef.destroy();
    if (this.closeToolRef) {
      this.closeToolRef.destroy();
    }
    this.destroyed$.next(null);
  }
}

enum EmbedScriptType {
  Inline,
  External
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  providers: [
  ]
})
export class ShellComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closeTool', { read: TemplateRef }) 
  closeToolTmpRef: TemplateRef<any>;
  
  @ViewChild("host", { read: ViewContainerRef }) 
  hostRef: ViewContainerRef;


  _title: string = "FootPrint Api";
  _logo: string = "";

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
    this.titleService.setTitle(this._title);
  }

  get logo(): string {
    return this._logo;
  }

  set logo(value: string) {
    this._logo = value;
    const links = document.getElementsByTagName("link");
    for(var i = 0; i < links.length; i++) {
      if (links[i].rel === "icon") {
        links[i].href = this._logo;
        links[i].type = undefined;
      }
    };
  }

  account: AccountInfo;
  showMainMenu = true;
  currentMenubarItem: IMenubarItem = null;
  homeMenubarItem: IMenubarItem = {
    id: 'home',
    label: 'Home',
    icon: 'icon-ic_fluent_home_20_regular',
  }

  items: IMenubarItem[] = [
  ]

  menubar = { 
  };

  crumbs: Crumb[] = [];

  loadingBarProgress: number = 0;
  destroyed$ = new Subject();
  initialized = false;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,

    private location: Location,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBarService: LoadingBarService,
    private titleService:Title,
    private datexErrorService: DatexErrorService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfiguration: MsalInterceptorConfiguration
  ) {
    super();
   }


  ngOnInit(): void {
    this.$init();
  }
  

  async $init() {
    const $workspace = this;
    const $utils = this.utils;


    this.account = this.authService.instance.getActiveAccount();

    this.initialized = true;
  }
  
  ngAfterViewInit(): void {

    this.loadingBarService.value$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(data => this.loadingBarProgress = data);

    this.route.queryParamMap.subscribe(params => {
      const view = this.route.snapshot.paramMap.get('view');

      if (view === 'home') {
        // TODO: ExpressionChangedAfterItHasBeenCheckedError
        Promise.resolve().then(() => this.openHome());
      } else if (view === 'preview') {
        Promise.resolve().then(() => this.openPreview(params));
      } else {
        const info = this.shell.getComponentInformation(view, params);

        if (info) {
          // TODO: ExpressionChangedAfterItHasBeenCheckedError
          Promise.resolve().then(() => this.openView(info.title, view, info.component, false, info.inParams));
        } else {
          const errorMesssage = `Unable to open view [${view}]. Unknown view`;
          console.error(errorMesssage);
          this.datexErrorService.add(new Error(errorMesssage));
          this.router.navigate(['error']);
        }
      }

    });

    ShellService.openViewRequest$.subscribe(request => {
      this.openView(request.title, request.referenceName, request.component, request.replaceCurrentView, request.inParams);
    });

  }

  ngOnDestroy(): void {
    this.clearCrumbs();
    this.destroyed$.next(null);
    this.destroyed$.unsubscribe();
  }

  onHamburgerClicked(): void {
    this.showMainMenu = !this.showMainMenu;
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  onCrumbClicked(crumb: Crumb) {
    this.activateCrumb(crumb);
  }

  onOpenHomeClicked() {
    this.currentMenubarItem = null;
    this.openHome();
  }

  onMenubarItemClicked(menubarItem: IMenubarItem) {
    if (menubarItem.items?.length) {
      if (this.currentMenubarItem && this.currentMenubarItem === menubarItem) {
        this.currentMenubarItem = null;
      } else {
        this.currentMenubarItem = menubarItem;
      }
    } else {
      this.currentMenubarItem = null;
      this.clearCrumbs();
      menubarItem.click(this.utils);
    }
  }

  onCloseClicked() {
    this.closeCrumbs(this.currentCrumb);
  }

  private addScript(type: EmbedScriptType, data: string) {
    switch(type){
      case EmbedScriptType.Inline:
        this.addInlineScript(data);
        break;
      case EmbedScriptType.External:
        this.addExternalScript(data);
        break;
      default:
        throw new Error(`Unknown script type ${type}`);
    }
  }

  private addInlineScript(body: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = body;
    document.body.appendChild(script);
  }

  private addExternalScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
  }

  private get currentCrumb() {
    return this.crumbs.find(c => c.active == true);
  }

  private clearCrumbs() {
    this.crumbs.forEach(c => c.destroy());
    this.crumbs.splice(0, this.crumbs.length);
  }

  private activateCrumb(crumb: Crumb, addToHost: boolean = true) {
    this.crumbs.forEach(c => { c.active = false; });
    crumb.active = true;
    let path = this.location.path().split('?')[0];
    path = path.split('/').slice(0, -1).join('/') + `/${crumb.referenceName}`;
    
    this.location.replaceState(path, crumb.queryString);

    if (addToHost) {
      this.hostRef.detach();
      this.hostRef.insert(crumb.componentRef.hostView);
      crumb.componentRef.instance.refresh?.();
    }
  }

  private closeCrumbs(startCrumb: Crumb, autoActivate = true) {
    const index = this.crumbs.findIndex(c => c === startCrumb);
     // delete everything from the right
    const deletedCrumbs = this.crumbs.splice(index, this.crumbs.length - index);
    if (deletedCrumbs) {
      deletedCrumbs.forEach(c => c.destroy());
    }

    if (autoActivate) {
      if (this.crumbs.length === 0) {
        this.openHome();
      } else {
        this.activateCrumb(this.crumbs[this.crumbs.length - 1]);
      }
    }
  }

  private openHome() {
    this.clearCrumbs();
    
    this.shell.openhome(
      false
    );
  }

  private openPreview(params: ParamMap) {
  }

  private openView(title: string, referenceName: string, component: any, replaceCurrentView?: boolean, inParams?: any): ComponentRef<any> {

    if (this.currentCrumb) {
      if (replaceCurrentView) {
        // Close current view without trying to activate previous blade or home
        this.closeCrumbs(this.currentCrumb, false);
      } else {
        // This closes the view next to the current view
        // so that it open the new view next to the current view
        const index = this.crumbs.findIndex(c => c === this.currentCrumb);
        const nextCrumb = this.crumbs[index + 1];
        if (nextCrumb) {
          this.closeCrumbs(nextCrumb, false);
        }
      }
    }

    this.hostRef.detach();
    let closeToolRef;
    let componentRef;

    if (this.crumbs.length > 0) {
      // create close button view
      closeToolRef = this.closeToolTmpRef.createEmbeddedView(null);

      // create the component and project the closetool view
      componentRef = this.hostRef.createComponent(component, {
        projectableNodes: [closeToolRef.rootNodes]
      });
    } else {
      // create the component without closetool
      componentRef = this.hostRef.createComponent(component, {
      });
    }

    // NOTE: because we are dynamically creating components
    // and setting the inputs programmatically, 
    // ngOnChanges of the component will not be called
    // if we wishes to do so, we can either have the component
    // inputs call detectchanges or call componentRef.hostView.detectChanges here
    // but probably don't need for this use case

    // set component inputs
    if (!isNil(inParams)) {
      Object.keys(inParams).forEach(k => {
        componentRef.instance['$inParams_'+k] = inParams[k];
      });
    }

    const crumb = new Crumb(title, referenceName, componentRef, closeToolRef, inParams);
    this.crumbs.push(crumb);
    this.activateCrumb(crumb, false);
    
    // From flow you call close(), it will emit $finish
    // so shell can close the view
    if (componentRef.instance['$finish']) {
      componentRef.instance['$finish']
      .pipe(
        // Just an easy way to unsub from subscription
        takeUntil(crumb.destroyed$)
      )
      .subscribe(() => {
        // TODO: There is an issue with replace view
        // if it is the only view, it will close, open the home 
        // then open the view that replace the current one
        this.closeCrumbs(crumb);
      });
    }
    
    if (componentRef.instance['$titleChange']) {
      componentRef.instance['$titleChange']
      .pipe(
        // Just an easy way to unsub from subscription
        takeUntil(crumb.destroyed$)
      )
      .subscribe(title => {
        crumb.title = title;
      });
    }

    return componentRef;
  }

}
