import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { SalesOrders_homeComponent } from './SalesOrders.home.component';
import { SalesOrders_load_slip_reportComponent } from './SalesOrders.load_slip_report.component';
import { SalesOrders_outbound_bill_of_lading_reportComponent } from './SalesOrders.outbound_bill_of_lading_report.component';
import { SalesOrders_packing_list_reportComponent } from './SalesOrders.packing_list_report.component';
import { SalesOrders_pick_slip_by_orderId_reportComponent } from './SalesOrders.pick_slip_by_orderId_report.component';


@Injectable({ providedIn: 'root' })
export class SalesOrders_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public SalesOrders: SalesOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'SalesOrders',
        referenceName: 'SalesOrders_home',
        component: SalesOrders_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      SalesOrders_homeComponent,
      'SalesOrders',
      mode,
      dialogSize
    )
  }
  public openload_slip_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Load Slip',
        referenceName: 'SalesOrders_load_slip_report',
        component: SalesOrders_load_slip_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openload_slip_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_load_slip_reportComponent,
      'Load Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoutbound_bill_of_lading_report(inParams:{ orderId: number, shipmentId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Bill of lading',
        referenceName: 'SalesOrders_outbound_bill_of_lading_report',
        component: SalesOrders_outbound_bill_of_lading_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_bill_of_lading_reportDialog(
    inParams:{ orderId: number, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_outbound_bill_of_lading_reportComponent,
      'Bill of lading',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpacking_list_report(inParams:{ orderId?: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Generic Packing List Report',
        referenceName: 'SalesOrders_packing_list_report',
        component: SalesOrders_packing_list_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpacking_list_reportDialog(
    inParams:{ orderId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_packing_list_reportComponent,
      'Generic Packing List Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_slip_by_orderId_report(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'SalesOrders_pick_slip_by_orderId_report',
        component: SalesOrders_pick_slip_by_orderId_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_slip_by_orderId_reportDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      SalesOrders_pick_slip_by_orderId_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'SalesOrders_home') {
      const title = 'SalesOrders';
      const component = SalesOrders_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_load_slip_report') {
      const title = 'Load Slip';
      const component = SalesOrders_load_slip_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_outbound_bill_of_lading_report') {
      const title = 'Bill of lading';
      const component = SalesOrders_outbound_bill_of_lading_reportComponent;
      const inParams:{ orderId: number, shipmentId?: number } = { orderId: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_packing_list_report') {
      const title = 'Generic Packing List Report';
      const component = SalesOrders_packing_list_reportComponent;
      const inParams:{ orderId?: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'SalesOrders_pick_slip_by_orderId_report') {
      const title = 'Pick Slip';
      const component = SalesOrders_pick_slip_by_orderId_reportComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
