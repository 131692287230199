import { Inject, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';



@Injectable({ providedIn: 'root' })
export class Notifications_OperationService {

  constructor(
    private injector: Injector,
    private utils: UtilsService,
  ) {
  }

  public Notifications: Notifications_OperationService = this;


  private isAuthorized(operationName: string): Promise<boolean> {
    return this.utils.http.post(`${environment.backendUrl}api/Notifications/operations/${operationName}/isauthorized`, null);
  }
}
