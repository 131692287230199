import { Inject, Injectable, Injector } from '@angular/core';


import { WarehouseTransfers_outbound_bill_of_lading_reportService } from './WarehouseTransfers.report.index';
import { WarehouseTransfers_outbound_pick_slip_reportService } from './WarehouseTransfers.report.index';

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public WarehouseTransfers: WarehouseTransfers_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _outbound_bill_of_lading_report: WarehouseTransfers_outbound_bill_of_lading_reportService;
  public get outbound_bill_of_lading_report(): WarehouseTransfers_outbound_bill_of_lading_reportService {
    if(!this._outbound_bill_of_lading_report) {
      this._outbound_bill_of_lading_report = this.injector.get(WarehouseTransfers_outbound_bill_of_lading_reportService);
    }
    return this._outbound_bill_of_lading_report;
  }
  private _outbound_pick_slip_report: WarehouseTransfers_outbound_pick_slip_reportService;
  public get outbound_pick_slip_report(): WarehouseTransfers_outbound_pick_slip_reportService {
    if(!this._outbound_pick_slip_report) {
      this._outbound_pick_slip_report = this.injector.get(WarehouseTransfers_outbound_pick_slip_reportService);
    }
    return this._outbound_pick_slip_report;
  }
}

