import { Inject, Injectable, Injector }from '@angular/core';


import { InventoryTransfers_ds_get_inventory_by_licenseplate_lot_packagingService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferIdService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferId_detailedService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_inventory_transfer_lines_by_inventoryTransferLineIdsService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_inventory_transfers_by_inventoryTransferIdsService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_matching_material_and_lotService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_material_packaging_by_materialId_packagingIdService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_get_materials_by_materialIdsService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_inventory_measurement_units_ddService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_inventory_transfer_editorService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_inventory_transfer_lines_gridService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_inventory_transfer_statuses_ddService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_inventory_transfers_gridService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_projects_ddService } from './InventoryTransfers.datasource.index';
import { InventoryTransfers_ds_vendorlots_ddService } from './InventoryTransfers.datasource.index';

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public InventoryTransfers: InventoryTransfers_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_inventory_by_licenseplate_lot_packaging: InventoryTransfers_ds_get_inventory_by_licenseplate_lot_packagingService;
  public get ds_get_inventory_by_licenseplate_lot_packaging(): InventoryTransfers_ds_get_inventory_by_licenseplate_lot_packagingService {
    if(!this._ds_get_inventory_by_licenseplate_lot_packaging) {
      this._ds_get_inventory_by_licenseplate_lot_packaging = this.injector.get(InventoryTransfers_ds_get_inventory_by_licenseplate_lot_packagingService);
    }
    return this._ds_get_inventory_by_licenseplate_lot_packaging;
  }
  private _ds_get_inventory_transfer_by_inventoryTransferId: InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferIdService;
  public get ds_get_inventory_transfer_by_inventoryTransferId(): InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferIdService {
    if(!this._ds_get_inventory_transfer_by_inventoryTransferId) {
      this._ds_get_inventory_transfer_by_inventoryTransferId = this.injector.get(InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferIdService);
    }
    return this._ds_get_inventory_transfer_by_inventoryTransferId;
  }
  private _ds_get_inventory_transfer_by_inventoryTransferId_detailed: InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferId_detailedService;
  public get ds_get_inventory_transfer_by_inventoryTransferId_detailed(): InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferId_detailedService {
    if(!this._ds_get_inventory_transfer_by_inventoryTransferId_detailed) {
      this._ds_get_inventory_transfer_by_inventoryTransferId_detailed = this.injector.get(InventoryTransfers_ds_get_inventory_transfer_by_inventoryTransferId_detailedService);
    }
    return this._ds_get_inventory_transfer_by_inventoryTransferId_detailed;
  }
  private _ds_get_inventory_transfer_lines_by_inventoryTransferLineIds: InventoryTransfers_ds_get_inventory_transfer_lines_by_inventoryTransferLineIdsService;
  public get ds_get_inventory_transfer_lines_by_inventoryTransferLineIds(): InventoryTransfers_ds_get_inventory_transfer_lines_by_inventoryTransferLineIdsService {
    if(!this._ds_get_inventory_transfer_lines_by_inventoryTransferLineIds) {
      this._ds_get_inventory_transfer_lines_by_inventoryTransferLineIds = this.injector.get(InventoryTransfers_ds_get_inventory_transfer_lines_by_inventoryTransferLineIdsService);
    }
    return this._ds_get_inventory_transfer_lines_by_inventoryTransferLineIds;
  }
  private _ds_get_inventory_transfers_by_inventoryTransferIds: InventoryTransfers_ds_get_inventory_transfers_by_inventoryTransferIdsService;
  public get ds_get_inventory_transfers_by_inventoryTransferIds(): InventoryTransfers_ds_get_inventory_transfers_by_inventoryTransferIdsService {
    if(!this._ds_get_inventory_transfers_by_inventoryTransferIds) {
      this._ds_get_inventory_transfers_by_inventoryTransferIds = this.injector.get(InventoryTransfers_ds_get_inventory_transfers_by_inventoryTransferIdsService);
    }
    return this._ds_get_inventory_transfers_by_inventoryTransferIds;
  }
  private _ds_get_matching_material_and_lot: InventoryTransfers_ds_get_matching_material_and_lotService;
  public get ds_get_matching_material_and_lot(): InventoryTransfers_ds_get_matching_material_and_lotService {
    if(!this._ds_get_matching_material_and_lot) {
      this._ds_get_matching_material_and_lot = this.injector.get(InventoryTransfers_ds_get_matching_material_and_lotService);
    }
    return this._ds_get_matching_material_and_lot;
  }
  private _ds_get_material_packaging_by_materialId_packagingId: InventoryTransfers_ds_get_material_packaging_by_materialId_packagingIdService;
  public get ds_get_material_packaging_by_materialId_packagingId(): InventoryTransfers_ds_get_material_packaging_by_materialId_packagingIdService {
    if(!this._ds_get_material_packaging_by_materialId_packagingId) {
      this._ds_get_material_packaging_by_materialId_packagingId = this.injector.get(InventoryTransfers_ds_get_material_packaging_by_materialId_packagingIdService);
    }
    return this._ds_get_material_packaging_by_materialId_packagingId;
  }
  private _ds_get_materials_by_materialIds: InventoryTransfers_ds_get_materials_by_materialIdsService;
  public get ds_get_materials_by_materialIds(): InventoryTransfers_ds_get_materials_by_materialIdsService {
    if(!this._ds_get_materials_by_materialIds) {
      this._ds_get_materials_by_materialIds = this.injector.get(InventoryTransfers_ds_get_materials_by_materialIdsService);
    }
    return this._ds_get_materials_by_materialIds;
  }
  private _ds_inventory_measurement_units_dd: InventoryTransfers_ds_inventory_measurement_units_ddService;
  public get ds_inventory_measurement_units_dd(): InventoryTransfers_ds_inventory_measurement_units_ddService {
    if(!this._ds_inventory_measurement_units_dd) {
      this._ds_inventory_measurement_units_dd = this.injector.get(InventoryTransfers_ds_inventory_measurement_units_ddService);
    }
    return this._ds_inventory_measurement_units_dd;
  }
  private _ds_inventory_transfer_editor: InventoryTransfers_ds_inventory_transfer_editorService;
  public get ds_inventory_transfer_editor(): InventoryTransfers_ds_inventory_transfer_editorService {
    if(!this._ds_inventory_transfer_editor) {
      this._ds_inventory_transfer_editor = this.injector.get(InventoryTransfers_ds_inventory_transfer_editorService);
    }
    return this._ds_inventory_transfer_editor;
  }
  private _ds_inventory_transfer_lines_grid: InventoryTransfers_ds_inventory_transfer_lines_gridService;
  public get ds_inventory_transfer_lines_grid(): InventoryTransfers_ds_inventory_transfer_lines_gridService {
    if(!this._ds_inventory_transfer_lines_grid) {
      this._ds_inventory_transfer_lines_grid = this.injector.get(InventoryTransfers_ds_inventory_transfer_lines_gridService);
    }
    return this._ds_inventory_transfer_lines_grid;
  }
  private _ds_inventory_transfer_statuses_dd: InventoryTransfers_ds_inventory_transfer_statuses_ddService;
  public get ds_inventory_transfer_statuses_dd(): InventoryTransfers_ds_inventory_transfer_statuses_ddService {
    if(!this._ds_inventory_transfer_statuses_dd) {
      this._ds_inventory_transfer_statuses_dd = this.injector.get(InventoryTransfers_ds_inventory_transfer_statuses_ddService);
    }
    return this._ds_inventory_transfer_statuses_dd;
  }
  private _ds_inventory_transfers_grid: InventoryTransfers_ds_inventory_transfers_gridService;
  public get ds_inventory_transfers_grid(): InventoryTransfers_ds_inventory_transfers_gridService {
    if(!this._ds_inventory_transfers_grid) {
      this._ds_inventory_transfers_grid = this.injector.get(InventoryTransfers_ds_inventory_transfers_gridService);
    }
    return this._ds_inventory_transfers_grid;
  }
  private _ds_projects_dd: InventoryTransfers_ds_projects_ddService;
  public get ds_projects_dd(): InventoryTransfers_ds_projects_ddService {
    if(!this._ds_projects_dd) {
      this._ds_projects_dd = this.injector.get(InventoryTransfers_ds_projects_ddService);
    }
    return this._ds_projects_dd;
  }
  private _ds_vendorlots_dd: InventoryTransfers_ds_vendorlots_ddService;
  public get ds_vendorlots_dd(): InventoryTransfers_ds_vendorlots_ddService {
    if(!this._ds_vendorlots_dd) {
      this._ds_vendorlots_dd = this.injector.get(InventoryTransfers_ds_vendorlots_ddService);
    }
    return this._ds_vendorlots_dd;
  }
}

