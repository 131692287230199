import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_licenseplate_details_by_serialIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { serialNumberId?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string } }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_licenseplate_details_by_serialId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { serialNumberId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_licenseplate_details_by_serialId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { serialNumberId?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, LookupCode?: string, LicensePlate?: { Id?: number, LookupCode?: string } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_licenseplate_details_by_serialId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
