import { Inject, Injectable, Injector } from '@angular/core';


import { SalesOrders_auto_load_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_auto_pick_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_cancel_manual_allocation_flowService } from './SalesOrders.flow.index';
import { SalesOrders_cancel_order_line_flowService } from './SalesOrders.flow.index';
import { SalesOrders_cancel_pick_task_flowService } from './SalesOrders.flow.index';
import { SalesOrders_cancel_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_cancel_sales_order_shipment_and_order_lines_flowService } from './SalesOrders.flow.index';
import { SalesOrders_complete_licenseplate_move_task_flowService } from './SalesOrders.flow.index';
import { SalesOrders_complete_manual_allocation_flowService } from './SalesOrders.flow.index';
import { SalesOrders_complete_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_copy_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_manual_allocation_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_sales_order_line_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_sales_order_line_in_wait_status_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_single_wave_flowService } from './SalesOrders.flow.index';
import { SalesOrders_create_wave_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_delete_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_delete_sales_order_line_flowService } from './SalesOrders.flow.index';
import { SalesOrders_excel_export_flowService } from './SalesOrders.flow.index';
import { SalesOrders_format_order_lookupcode_flowService } from './SalesOrders.flow.index';
import { SalesOrders_format_order_lookupcode_flow_multipleService } from './SalesOrders.flow.index';
import { SalesOrders_get_order_signature_by_orderId_flowService } from './SalesOrders.flow.index';
import { SalesOrders_get_sales_order_line_availability_flowService } from './SalesOrders.flow.index';
import { SalesOrders_get_sales_order_stateService } from './SalesOrders.flow.index';
import { SalesOrders_group_pick_tasks_by_license_plate_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_manual_allocation_cancelable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_pick_task_cancelable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_cancelable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_completable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_deletable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_line_deletable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_loadable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_pickable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_processable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_revertable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_is_sales_order_soft_allocatable_flowService } from './SalesOrders.flow.index';
import { SalesOrders_pick_full_license_plateService } from './SalesOrders.flow.index';
import { SalesOrders_pick_on_existing_license_plateService } from './SalesOrders.flow.index';
import { SalesOrders_pick_on_new_license_plateService } from './SalesOrders.flow.index';
import { SalesOrders_process_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_process_single_wave_flowService } from './SalesOrders.flow.index';
import { SalesOrders_process_wave_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_reallocate_pick_task_flowService } from './SalesOrders.flow.index';
import { SalesOrders_release_single_wave_flowService } from './SalesOrders.flow.index';
import { SalesOrders_release_wave_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_reprocess_new_sales_order_lines_by_shipmentService } from './SalesOrders.flow.index';
import { SalesOrders_revert_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_substitution_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_transfer_auto_pick_sales_order_flowService } from './SalesOrders.flow.index';
import { SalesOrders_transfer_licenseplates_to_new_salesorder_flowService } from './SalesOrders.flow.index';
import { SalesOrders_unload_shipping_licenseplate_flowService } from './SalesOrders.flow.index';
import { SalesOrders_unpick_inventory_flowService } from './SalesOrders.flow.index';
import { SalesOrders_unpick_licenseplate_flowService } from './SalesOrders.flow.index';

import { $frontendTypes } from './SalesOrders.frontend.types'

@Injectable({ providedIn: 'root' })
export class SalesOrders_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public SalesOrders: SalesOrders_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _auto_load_sales_order_flow: SalesOrders_auto_load_sales_order_flowService;
  public async auto_load_sales_order_flow(inParams: { orderId: number, shipmentId: number, loadLocation?: number }): Promise< { reason?: string }> {
    if(!this._auto_load_sales_order_flow) {
      this._auto_load_sales_order_flow = this.injector.get(SalesOrders_auto_load_sales_order_flowService);
    }
    return this._auto_load_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _auto_pick_sales_order_flow: SalesOrders_auto_pick_sales_order_flowService;
  public async auto_pick_sales_order_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._auto_pick_sales_order_flow) {
      this._auto_pick_sales_order_flow = this.injector.get(SalesOrders_auto_pick_sales_order_flowService);
    }
    return this._auto_pick_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _cancel_manual_allocation_flow: SalesOrders_cancel_manual_allocation_flowService;
  public async cancel_manual_allocation_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._cancel_manual_allocation_flow) {
      this._cancel_manual_allocation_flow = this.injector.get(SalesOrders_cancel_manual_allocation_flowService);
    }
    return this._cancel_manual_allocation_flow.run(inParams);
  }
   
   

   
 
  private _cancel_order_line_flow: SalesOrders_cancel_order_line_flowService;
  public async cancel_order_line_flow(inParams: { orderId: number, orderLineNumbers?: number[], reasonCodeId: number, reasonNotes?: string }): Promise< { reasons?: string[] }> {
    if(!this._cancel_order_line_flow) {
      this._cancel_order_line_flow = this.injector.get(SalesOrders_cancel_order_line_flowService);
    }
    return this._cancel_order_line_flow.run(inParams);
  }
   
   

   
 
  private _cancel_pick_task_flow: SalesOrders_cancel_pick_task_flowService;
  public async cancel_pick_task_flow(inParams: { taskId: number, reasonCodeId?: number }): Promise< { reason?: string }> {
    if(!this._cancel_pick_task_flow) {
      this._cancel_pick_task_flow = this.injector.get(SalesOrders_cancel_pick_task_flowService);
    }
    return this._cancel_pick_task_flow.run(inParams);
  }
   
   

   
 
  private _cancel_sales_order_flow: SalesOrders_cancel_sales_order_flowService;
  public async cancel_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._cancel_sales_order_flow) {
      this._cancel_sales_order_flow = this.injector.get(SalesOrders_cancel_sales_order_flowService);
    }
    return this._cancel_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _cancel_sales_order_shipment_and_order_lines_flow: SalesOrders_cancel_sales_order_shipment_and_order_lines_flowService;
  public async cancel_sales_order_shipment_and_order_lines_flow(inParams: { shipmentId: number, reasonCodeId: number }): Promise< { reason?: string }> {
    if(!this._cancel_sales_order_shipment_and_order_lines_flow) {
      this._cancel_sales_order_shipment_and_order_lines_flow = this.injector.get(SalesOrders_cancel_sales_order_shipment_and_order_lines_flowService);
    }
    return this._cancel_sales_order_shipment_and_order_lines_flow.run(inParams);
  }
   
   

   
 
  private _complete_licenseplate_move_task_flow: SalesOrders_complete_licenseplate_move_task_flowService;
  public async complete_licenseplate_move_task_flow(inParams: { licenseplateId: number, locationId: number }): Promise< { reason?: string }> {
    if(!this._complete_licenseplate_move_task_flow) {
      this._complete_licenseplate_move_task_flow = this.injector.get(SalesOrders_complete_licenseplate_move_task_flowService);
    }
    return this._complete_licenseplate_move_task_flow.run(inParams);
  }
   
   

   
 
  private _complete_manual_allocation_flow: SalesOrders_complete_manual_allocation_flowService;
  public async complete_manual_allocation_flow(inParams: { taskId: number, lotId: number, locationId: number, vendorLotId: number, currentPackagedAmount: number, updatedPackagedAmount: number }): Promise< { reason?: string }> {
    if(!this._complete_manual_allocation_flow) {
      this._complete_manual_allocation_flow = this.injector.get(SalesOrders_complete_manual_allocation_flowService);
    }
    return this._complete_manual_allocation_flow.run(inParams);
  }
   
   

   
 
  private _complete_sales_order_flow: SalesOrders_complete_sales_order_flowService;
  public async complete_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._complete_sales_order_flow) {
      this._complete_sales_order_flow = this.injector.get(SalesOrders_complete_sales_order_flowService);
    }
    return this._complete_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _copy_sales_order_flow: SalesOrders_copy_sales_order_flowService;
  public async copy_sales_order_flow(inParams: { orderId: number, copyAddresses: boolean }): Promise< { newOrderId?: number, reason?: string, newShipmentId?: number, orderId?: number }> {
    if(!this._copy_sales_order_flow) {
      this._copy_sales_order_flow = this.injector.get(SalesOrders_copy_sales_order_flowService);
    }
    return this._copy_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _create_manual_allocation_flow: SalesOrders_create_manual_allocation_flowService;
  public async create_manual_allocation_flow(inParams: { materialId: number, packagingId: number, vendorLotId?: number, lotId?: number, licensePlateId?: number, sourceLocationId?: number, packagedAmount: number, shipmentLineId: number }): Promise< { reasons?: string[], manualAllocationTaskId?: number }> {
    if(!this._create_manual_allocation_flow) {
      this._create_manual_allocation_flow = this.injector.get(SalesOrders_create_manual_allocation_flowService);
    }
    return this._create_manual_allocation_flow.run(inParams);
  }
   
   

   
 
  private _create_sales_order_flow: SalesOrders_create_sales_order_flowService;
  public async create_sales_order_flow(inParams: { lookupcode?: string, projectId: number, warehouseId: number, orderClassId: number, ownerReference?: string, waitStatus?: boolean }): Promise< { reason?: string, orderId?: number, shipmentId?: number }> {
    if(!this._create_sales_order_flow) {
      this._create_sales_order_flow = this.injector.get(SalesOrders_create_sales_order_flowService);
    }
    return this._create_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _create_sales_order_line_flow: SalesOrders_create_sales_order_line_flowService;
  public async create_sales_order_line_flow(inParams: { orderId: number, shipmentId: number, packagingId?: number, packagedAmount?: number, materialId?: number, lotId?: number, vendorlotId?: number, serialnumberId?: number, licenseplateId?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_sales_order_line_flow) {
      this._create_sales_order_line_flow = this.injector.get(SalesOrders_create_sales_order_line_flowService);
    }
    return this._create_sales_order_line_flow.run(inParams);
  }
   
   

   
 
  private _create_sales_order_line_in_wait_status_flow: SalesOrders_create_sales_order_line_in_wait_status_flowService;
  public async create_sales_order_line_in_wait_status_flow(inParams: { projectId: number, orderId: number, shipmentId: number, materialId: number, packagingId: number, packagedAmount: number, lotId?: number, vendorLotId?: number, notes?: string, serialnumberId?: number, licenseplateId?: number }): Promise< { lineNumber?: number, reason?: string }> {
    if(!this._create_sales_order_line_in_wait_status_flow) {
      this._create_sales_order_line_in_wait_status_flow = this.injector.get(SalesOrders_create_sales_order_line_in_wait_status_flowService);
    }
    return this._create_sales_order_line_in_wait_status_flow.run(inParams);
  }
   
   

   
 
  private _create_single_wave_flow: SalesOrders_create_single_wave_flowService;
  public async create_single_wave_flow(inParams: { warehouseId: number, description: string, priorityId: number, batchPickLocationId?: number }): Promise< { waveId?: number, reason?: string }> {
    if(!this._create_single_wave_flow) {
      this._create_single_wave_flow = this.injector.get(SalesOrders_create_single_wave_flowService);
    }
    return this._create_single_wave_flow.run(inParams);
  }
   
   

   
 
  private _create_wave_sales_order_flow: SalesOrders_create_wave_sales_order_flowService;
  public async create_wave_sales_order_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._create_wave_sales_order_flow) {
      this._create_wave_sales_order_flow = this.injector.get(SalesOrders_create_wave_sales_order_flowService);
    }
    return this._create_wave_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_sales_order_flow: SalesOrders_delete_sales_order_flowService;
  public async delete_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._delete_sales_order_flow) {
      this._delete_sales_order_flow = this.injector.get(SalesOrders_delete_sales_order_flowService);
    }
    return this._delete_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_sales_order_line_flow: SalesOrders_delete_sales_order_line_flowService;
  public async delete_sales_order_line_flow(inParams: { orderId: number, lineNumber: number }): Promise< { reason?: string }> {
    if(!this._delete_sales_order_line_flow) {
      this._delete_sales_order_line_flow = this.injector.get(SalesOrders_delete_sales_order_line_flowService);
    }
    return this._delete_sales_order_line_flow.run(inParams);
  }
   
   

   
 
  private _excel_export_flow: SalesOrders_excel_export_flowService;
  public async excel_export_flow(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string, carrierId?: number, carrierServiceTypeId?: number, materialIds?: number[], fullTextSearch?: string, reference?: string, orderIds?: number[], exportLimit?: number, exportType?: string, dateType?: string, dateFormat?: string, countries?: string[], waveId?: number, ship_to_search?: string }): Promise< { transactions?: any, logMessage?: string[], debugging?: any[] }> {
    if(!this._excel_export_flow) {
      this._excel_export_flow = this.injector.get(SalesOrders_excel_export_flowService);
    }
    return this._excel_export_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow: SalesOrders_format_order_lookupcode_flowService;
  public async format_order_lookupcode_flow(inParams: { orderId: number, additionalData?: any, context: string }): Promise< { formattedLookupcode?: string, errors?: string[] }> {
    if(!this._format_order_lookupcode_flow) {
      this._format_order_lookupcode_flow = this.injector.get(SalesOrders_format_order_lookupcode_flowService);
    }
    return this._format_order_lookupcode_flow.run(inParams);
  }
   
   

   
 
  private _format_order_lookupcode_flow_multiple: SalesOrders_format_order_lookupcode_flow_multipleService;
  public async format_order_lookupcode_flow_multiple(inParams: { context: string, orders?: { orderId?: number, additionalData?: any }[] }): Promise< { errors?: string[], orders?: { orderId?: number, formattedLookupCode?: string }[] }> {
    if(!this._format_order_lookupcode_flow_multiple) {
      this._format_order_lookupcode_flow_multiple = this.injector.get(SalesOrders_format_order_lookupcode_flow_multipleService);
    }
    return this._format_order_lookupcode_flow_multiple.run(inParams);
  }
   
   

   
 
  private _get_order_signature_by_orderId_flow: SalesOrders_get_order_signature_by_orderId_flowService;
  public async get_order_signature_by_orderId_flow(inParams: {  }): Promise<void> {
    if(!this._get_order_signature_by_orderId_flow) {
      this._get_order_signature_by_orderId_flow = this.injector.get(SalesOrders_get_order_signature_by_orderId_flowService);
    }
    return this._get_order_signature_by_orderId_flow.run(inParams);
  }
   
   

   
 
  private _get_sales_order_line_availability_flow: SalesOrders_get_sales_order_line_availability_flowService;
  public async get_sales_order_line_availability_flow(inParams: { warehouseId: number, materialId: number, packagedId: number, lotId?: number, licenseplateId?: number, serialnumberId?: number, locationId?: number }): Promise< { availableByMaterial?: number, availableByLot?: number, availableByLicenseplate?: number, availableBySerialnumber?: number, availableHelper?: string, nonallocatableHelper?: string }> {
    if(!this._get_sales_order_line_availability_flow) {
      this._get_sales_order_line_availability_flow = this.injector.get(SalesOrders_get_sales_order_line_availability_flowService);
    }
    return this._get_sales_order_line_availability_flow.run(inParams);
  }
   
   

   
 
  private _get_sales_order_state: SalesOrders_get_sales_order_stateService;
  public async get_sales_order_state(inParams: { orders: { orderId: number, orderStatusId?: number, orderStatus?: string, requestedDeliveryDate?: string, shipmentId?: number, shipmentStatusId?: number, expectedDate?: string, containerSize?: string, waveId?: number, waveStatus?: string, waveStatusId?: number }[] }): Promise< { states?: { orderId?: number, stateId?: number, stateName?: string, has_manual?: boolean, picked_total?: number, expected_total?: number }[], messages?: any[] }> {
    if(!this._get_sales_order_state) {
      this._get_sales_order_state = this.injector.get(SalesOrders_get_sales_order_stateService);
    }
    return this._get_sales_order_state.run(inParams);
  }
   
   

   
 
  private _group_pick_tasks_by_license_plate_flow: SalesOrders_group_pick_tasks_by_license_plate_flowService;
  public async group_pick_tasks_by_license_plate_flow(inParams: { task_ids: number[] }): Promise< { reasons?: string[] }> {
    if(!this._group_pick_tasks_by_license_plate_flow) {
      this._group_pick_tasks_by_license_plate_flow = this.injector.get(SalesOrders_group_pick_tasks_by_license_plate_flowService);
    }
    return this._group_pick_tasks_by_license_plate_flow.run(inParams);
  }
   
   

   
 
  private _is_manual_allocation_cancelable_flow: SalesOrders_is_manual_allocation_cancelable_flowService;
  public async is_manual_allocation_cancelable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_manual_allocation_cancelable_flow) {
      this._is_manual_allocation_cancelable_flow = this.injector.get(SalesOrders_is_manual_allocation_cancelable_flowService);
    }
    return this._is_manual_allocation_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_pick_task_cancelable_flow: SalesOrders_is_pick_task_cancelable_flowService;
  public async is_pick_task_cancelable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_pick_task_cancelable_flow) {
      this._is_pick_task_cancelable_flow = this.injector.get(SalesOrders_is_pick_task_cancelable_flowService);
    }
    return this._is_pick_task_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_cancelable_flow: SalesOrders_is_sales_order_cancelable_flowService;
  public async is_sales_order_cancelable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string, can_cancel?: boolean, one_remaining_pick_task?: boolean }> {
    if(!this._is_sales_order_cancelable_flow) {
      this._is_sales_order_cancelable_flow = this.injector.get(SalesOrders_is_sales_order_cancelable_flowService);
    }
    return this._is_sales_order_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_completable_flow: SalesOrders_is_sales_order_completable_flowService;
  public async is_sales_order_completable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_completable_flow) {
      this._is_sales_order_completable_flow = this.injector.get(SalesOrders_is_sales_order_completable_flowService);
    }
    return this._is_sales_order_completable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_deletable_flow: SalesOrders_is_sales_order_deletable_flowService;
  public async is_sales_order_deletable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_deletable_flow) {
      this._is_sales_order_deletable_flow = this.injector.get(SalesOrders_is_sales_order_deletable_flowService);
    }
    return this._is_sales_order_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_line_deletable_flow: SalesOrders_is_sales_order_line_deletable_flowService;
  public async is_sales_order_line_deletable_flow(inParams: { orderId: number, lineNumber: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_line_deletable_flow) {
      this._is_sales_order_line_deletable_flow = this.injector.get(SalesOrders_is_sales_order_line_deletable_flowService);
    }
    return this._is_sales_order_line_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_loadable_flow: SalesOrders_is_sales_order_loadable_flowService;
  public async is_sales_order_loadable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string, pendingLoadCount?: number }> {
    if(!this._is_sales_order_loadable_flow) {
      this._is_sales_order_loadable_flow = this.injector.get(SalesOrders_is_sales_order_loadable_flowService);
    }
    return this._is_sales_order_loadable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_pickable_flow: SalesOrders_is_sales_order_pickable_flowService;
  public async is_sales_order_pickable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_pickable_flow) {
      this._is_sales_order_pickable_flow = this.injector.get(SalesOrders_is_sales_order_pickable_flowService);
    }
    return this._is_sales_order_pickable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_processable_flow: SalesOrders_is_sales_order_processable_flowService;
  public async is_sales_order_processable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_processable_flow) {
      this._is_sales_order_processable_flow = this.injector.get(SalesOrders_is_sales_order_processable_flowService);
    }
    return this._is_sales_order_processable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_revertable_flow: SalesOrders_is_sales_order_revertable_flowService;
  public async is_sales_order_revertable_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_revertable_flow) {
      this._is_sales_order_revertable_flow = this.injector.get(SalesOrders_is_sales_order_revertable_flowService);
    }
    return this._is_sales_order_revertable_flow.run(inParams);
  }
   
   

   
 
  private _is_sales_order_soft_allocatable_flow: SalesOrders_is_sales_order_soft_allocatable_flowService;
  public async is_sales_order_soft_allocatable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._is_sales_order_soft_allocatable_flow) {
      this._is_sales_order_soft_allocatable_flow = this.injector.get(SalesOrders_is_sales_order_soft_allocatable_flowService);
    }
    return this._is_sales_order_soft_allocatable_flow.run(inParams);
  }
   
   

   
 
  private _pick_full_license_plate: SalesOrders_pick_full_license_plateService;
  public async pick_full_license_plate(inParams: { taskId: number, targetLocationId: number }): Promise< { ExecutionTimeInMilliseconds?: number, TargetShippingContainerId?: number, reason?: string }> {
    if(!this._pick_full_license_plate) {
      this._pick_full_license_plate = this.injector.get(SalesOrders_pick_full_license_plateService);
    }
    return this._pick_full_license_plate.run(inParams);
  }
   
   

   
 
  private _pick_on_existing_license_plate: SalesOrders_pick_on_existing_license_plateService;
  public async pick_on_existing_license_plate(inParams: { taskId?: number, packagedAmount?: number, packagedPackId?: number, sourceLicensePlateId?: number, targetLicensePlateId?: number, serialNumberIds?: number[] }): Promise< { ExecutionTimeInMilliseconds?: number, TaskId?: number }> {
    if(!this._pick_on_existing_license_plate) {
      this._pick_on_existing_license_plate = this.injector.get(SalesOrders_pick_on_existing_license_plateService);
    }
    return this._pick_on_existing_license_plate.run(inParams);
  }
   
   

   
 
  private _pick_on_new_license_plate: SalesOrders_pick_on_new_license_plateService;
  public async pick_on_new_license_plate(inParams: { taskId?: number, packagedAmount?: number, packagedPackId?: number, sourceLicensePlateId?: number, targetLocationId?: number, targetLicensePlateLookupCode?: string, serialNumberIds?: number[] }): Promise< { ExecutionTimeInMilliseconds?: number, LicensePlateId?: number, TargetShippingContainerId?: number, TaskId?: number }> {
    if(!this._pick_on_new_license_plate) {
      this._pick_on_new_license_plate = this.injector.get(SalesOrders_pick_on_new_license_plateService);
    }
    return this._pick_on_new_license_plate.run(inParams);
  }
   
   

   
 
  private _process_sales_order_flow: SalesOrders_process_sales_order_flowService;
  public async process_sales_order_flow(inParams: { order_id: number, shipment_id: number, processing_strategy_workflow_code?: string, break_child_serials?: boolean }): Promise< { reason?: string }> {
    if(!this._process_sales_order_flow) {
      this._process_sales_order_flow = this.injector.get(SalesOrders_process_sales_order_flowService);
    }
    return this._process_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _process_single_wave_flow: SalesOrders_process_single_wave_flowService;
  public async process_single_wave_flow(inParams: { waveId: number, createManualAllocationTasks: boolean }): Promise< { reason?: string }> {
    if(!this._process_single_wave_flow) {
      this._process_single_wave_flow = this.injector.get(SalesOrders_process_single_wave_flowService);
    }
    return this._process_single_wave_flow.run(inParams);
  }
   
   

   
 
  private _process_wave_sales_order_flow: SalesOrders_process_wave_sales_order_flowService;
  public async process_wave_sales_order_flow(inParams: { orderId: number, shipmentId: number, is_create_manual_allocation_tasks: boolean }): Promise< { reason?: string }> {
    if(!this._process_wave_sales_order_flow) {
      this._process_wave_sales_order_flow = this.injector.get(SalesOrders_process_wave_sales_order_flowService);
    }
    return this._process_wave_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _reallocate_pick_task_flow: SalesOrders_reallocate_pick_task_flowService;
  public async reallocate_pick_task_flow(inParams: { taskId: number, lotId: number, locationId: number, vendorLotId: number, currentPackagedAmount: number, updatedPackagedAmount: number }): Promise< { reason?: string }> {
    if(!this._reallocate_pick_task_flow) {
      this._reallocate_pick_task_flow = this.injector.get(SalesOrders_reallocate_pick_task_flowService);
    }
    return this._reallocate_pick_task_flow.run(inParams);
  }
   
   

   
 
  private _release_single_wave_flow: SalesOrders_release_single_wave_flowService;
  public async release_single_wave_flow(inParams: { waveId: number }): Promise< { reason?: string }> {
    if(!this._release_single_wave_flow) {
      this._release_single_wave_flow = this.injector.get(SalesOrders_release_single_wave_flowService);
    }
    return this._release_single_wave_flow.run(inParams);
  }
   
   

   
 
  private _release_wave_sales_order_flow: SalesOrders_release_wave_sales_order_flowService;
  public async release_wave_sales_order_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._release_wave_sales_order_flow) {
      this._release_wave_sales_order_flow = this.injector.get(SalesOrders_release_wave_sales_order_flowService);
    }
    return this._release_wave_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _reprocess_new_sales_order_lines_by_shipment: SalesOrders_reprocess_new_sales_order_lines_by_shipmentService;
  public async reprocess_new_sales_order_lines_by_shipment(inParams: { order_id: number, shipment_id: number, processing_strategy_workflow_code: string }): Promise< { reason?: string, result?: any }> {
    if(!this._reprocess_new_sales_order_lines_by_shipment) {
      this._reprocess_new_sales_order_lines_by_shipment = this.injector.get(SalesOrders_reprocess_new_sales_order_lines_by_shipmentService);
    }
    return this._reprocess_new_sales_order_lines_by_shipment.run(inParams);
  }
   
   

   
 
  private _revert_sales_order_flow: SalesOrders_revert_sales_order_flowService;
  public async revert_sales_order_flow(inParams: { orderId: number, shipmentId: number }): Promise< { reason?: string }> {
    if(!this._revert_sales_order_flow) {
      this._revert_sales_order_flow = this.injector.get(SalesOrders_revert_sales_order_flowService);
    }
    return this._revert_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _substitution_sales_order_flow: SalesOrders_substitution_sales_order_flowService;
  public async substitution_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._substitution_sales_order_flow) {
      this._substitution_sales_order_flow = this.injector.get(SalesOrders_substitution_sales_order_flowService);
    }
    return this._substitution_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _transfer_auto_pick_sales_order_flow: SalesOrders_transfer_auto_pick_sales_order_flowService;
  public async transfer_auto_pick_sales_order_flow(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._transfer_auto_pick_sales_order_flow) {
      this._transfer_auto_pick_sales_order_flow = this.injector.get(SalesOrders_transfer_auto_pick_sales_order_flowService);
    }
    return this._transfer_auto_pick_sales_order_flow.run(inParams);
  }
   
   

   
 
  private _transfer_licenseplates_to_new_salesorder_flow: SalesOrders_transfer_licenseplates_to_new_salesorder_flowService;
  public async transfer_licenseplates_to_new_salesorder_flow(inParams: { licenseplateIds: number[] }): Promise< { reasons?: string[] }> {
    if(!this._transfer_licenseplates_to_new_salesorder_flow) {
      this._transfer_licenseplates_to_new_salesorder_flow = this.injector.get(SalesOrders_transfer_licenseplates_to_new_salesorder_flowService);
    }
    return this._transfer_licenseplates_to_new_salesorder_flow.run(inParams);
  }
   
   

   
 
  private _unload_shipping_licenseplate_flow: SalesOrders_unload_shipping_licenseplate_flowService;
  public async unload_shipping_licenseplate_flow(inParams: { licenseplateId: number, shipmentId: number, notes?: string, locationId: number }): Promise< { reason?: string }> {
    if(!this._unload_shipping_licenseplate_flow) {
      this._unload_shipping_licenseplate_flow = this.injector.get(SalesOrders_unload_shipping_licenseplate_flowService);
    }
    return this._unload_shipping_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _unpick_inventory_flow: SalesOrders_unpick_inventory_flowService;
  public async unpick_inventory_flow(inParams: { licenseplateId: number, lotId: number, packagingId: number, unpickPackagedAmount: number, targetLicensePlate: { id?: number, lookupcode?: string, locationId?: number }, serialNumberIds?: number[], shipmentLineId: number, notes?: string }): Promise< { reasons?: string[] }> {
    if(!this._unpick_inventory_flow) {
      this._unpick_inventory_flow = this.injector.get(SalesOrders_unpick_inventory_flowService);
    }
    return this._unpick_inventory_flow.run(inParams);
  }
   
   

   
 
  private _unpick_licenseplate_flow: SalesOrders_unpick_licenseplate_flowService;
  public async unpick_licenseplate_flow(inParams: { licenseplateId: number, targetLocationId?: number }): Promise< { reason?: string }> {
    if(!this._unpick_licenseplate_flow) {
      this._unpick_licenseplate_flow = this.injector.get(SalesOrders_unpick_licenseplate_flowService);
    }
    return this._unpick_licenseplate_flow.run(inParams);
  }
   
   

   
}
