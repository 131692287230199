import { Inject, Injectable, Injector } from '@angular/core';



@Injectable({ providedIn: 'root' })
export class Attachments_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public Attachments: Attachments_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

