import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_cycle_count_reportServiceInParams {
  cycle_count_id: number}

interface IReports_custom_cycle_count_reportServiceData {
  details?: { result?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string, Status?: { Name?: string } }, Material?: { Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string }, MaterialGroup?: { Name?: string } }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, recount?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string } }[] }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_cycle_count_reportService extends ReportBaseService<IReports_custom_cycle_count_reportServiceInParams, IReports_custom_cycle_count_reportServiceData> {

  protected reportReferenceName = 'custom_cycle_count_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_cycle_count_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_cycle_count_reportServiceInParams): Promise<IReports_custom_cycle_count_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_cycle_count_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_cycle_count_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        cycle_count_id:  $report.inParams.cycle_count_id ,
        operation_code_id:  58 ,
        chain_head:  null 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_cycle_count_report_details.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
