import { Inject, Injectable, Injector } from '@angular/core';



@Injectable({ providedIn: 'root' })
export class InventoryTransfers_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public InventoryTransfers: InventoryTransfers_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

