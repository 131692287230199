import { Inject, Injectable, Injector }from '@angular/core';


import { PackVerification_ds_containertypes_ddService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_completed_pack_verification_task_by_serialNumberIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_completed_serialnumbermove_task_by_serialNumberIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_container_type_by_containerTypeIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_failed_verification_reasoncodes_by_shippingContainerIdsService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_instructions_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_licenseplatecontents_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_licenseplates_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_location_by_locationIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_material_packaging_lookup_by_materialId_packagingIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_open_start_end_pack_verification_task_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_operationcode_by_operationCodeName_top1Service } from './PackVerification.datasource.index';
import { PackVerification_ds_get_pack_verification_tasks_by_contentService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_pack_verification_tasks_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_pick_slip_by_pickSlipIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_picking_tasks_by_licenseplateIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_reasoncode_by_reasonCodeIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_serialnumber_by_serialNumberCodeService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_serialnumber_by_serialNumberIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_serialnumber_by_shippingContainerId_serialNumberCodeService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_serialnumbers_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shipping_containers_by_shipmentIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shipping_containers_not_verified_by_shipmentIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontainer_by_shippingContainerCodeService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontainer_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontainer_contentService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontainer_instructionsService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontainers_by_warehouseIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_shippingcontents_by_licenseplateId_lotId_packagingIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_start_pack_verification_task_by_taskIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_start_pack_verification_tasks_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_task_by_taskIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_unassigned_pack_verification_tasks_by_shippingContainerIdService } from './PackVerification.datasource.index';
import { PackVerification_ds_get_upc_by_materal_idsService } from './PackVerification.datasource.index';
import { PackVerification_ds_measurementunits_ddService } from './PackVerification.datasource.index';
import { PackVerification_ds_pack_verification_shipping_container_contents_gridService } from './PackVerification.datasource.index';
import { PackVerification_ds_pack_verification_verify_container_editorService } from './PackVerification.datasource.index';
import { PackVerification_ds_reasoncodes_ddService } from './PackVerification.datasource.index';
import { PackVerification_ds_scanned_values_gridService } from './PackVerification.datasource.index';
import { PackVerification_ds_shipping_container_pack_verification_tasks_gridService } from './PackVerification.datasource.index';
import { PackVerification_ds_shippingcontainers_failed_verification_gridService } from './PackVerification.datasource.index';
import { PackVerification_ds_shippingcontainers_failed_verification_widgetService } from './PackVerification.datasource.index';
import { PackVerification_ds_shippingcontainers_to_verify_gridService } from './PackVerification.datasource.index';
import { PackVerification_ds_shippingcontainers_to_verify_widgetService } from './PackVerification.datasource.index';
import { PackVerification_ds_verification_locations_ddService } from './PackVerification.datasource.index';

@Injectable({ providedIn: 'root' })
export class PackVerification_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public PackVerification: PackVerification_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_containertypes_dd: PackVerification_ds_containertypes_ddService;
  public get ds_containertypes_dd(): PackVerification_ds_containertypes_ddService {
    if(!this._ds_containertypes_dd) {
      this._ds_containertypes_dd = this.injector.get(PackVerification_ds_containertypes_ddService);
    }
    return this._ds_containertypes_dd;
  }
  private _ds_get_completed_pack_verification_task_by_serialNumberId: PackVerification_ds_get_completed_pack_verification_task_by_serialNumberIdService;
  public get ds_get_completed_pack_verification_task_by_serialNumberId(): PackVerification_ds_get_completed_pack_verification_task_by_serialNumberIdService {
    if(!this._ds_get_completed_pack_verification_task_by_serialNumberId) {
      this._ds_get_completed_pack_verification_task_by_serialNumberId = this.injector.get(PackVerification_ds_get_completed_pack_verification_task_by_serialNumberIdService);
    }
    return this._ds_get_completed_pack_verification_task_by_serialNumberId;
  }
  private _ds_get_completed_serialnumbermove_task_by_serialNumberId: PackVerification_ds_get_completed_serialnumbermove_task_by_serialNumberIdService;
  public get ds_get_completed_serialnumbermove_task_by_serialNumberId(): PackVerification_ds_get_completed_serialnumbermove_task_by_serialNumberIdService {
    if(!this._ds_get_completed_serialnumbermove_task_by_serialNumberId) {
      this._ds_get_completed_serialnumbermove_task_by_serialNumberId = this.injector.get(PackVerification_ds_get_completed_serialnumbermove_task_by_serialNumberIdService);
    }
    return this._ds_get_completed_serialnumbermove_task_by_serialNumberId;
  }
  private _ds_get_container_type_by_containerTypeId: PackVerification_ds_get_container_type_by_containerTypeIdService;
  public get ds_get_container_type_by_containerTypeId(): PackVerification_ds_get_container_type_by_containerTypeIdService {
    if(!this._ds_get_container_type_by_containerTypeId) {
      this._ds_get_container_type_by_containerTypeId = this.injector.get(PackVerification_ds_get_container_type_by_containerTypeIdService);
    }
    return this._ds_get_container_type_by_containerTypeId;
  }
  private _ds_get_failed_verification_reasoncodes_by_shippingContainerIds: PackVerification_ds_get_failed_verification_reasoncodes_by_shippingContainerIdsService;
  public get ds_get_failed_verification_reasoncodes_by_shippingContainerIds(): PackVerification_ds_get_failed_verification_reasoncodes_by_shippingContainerIdsService {
    if(!this._ds_get_failed_verification_reasoncodes_by_shippingContainerIds) {
      this._ds_get_failed_verification_reasoncodes_by_shippingContainerIds = this.injector.get(PackVerification_ds_get_failed_verification_reasoncodes_by_shippingContainerIdsService);
    }
    return this._ds_get_failed_verification_reasoncodes_by_shippingContainerIds;
  }
  private _ds_get_instructions_by_shippingContainerId: PackVerification_ds_get_instructions_by_shippingContainerIdService;
  public get ds_get_instructions_by_shippingContainerId(): PackVerification_ds_get_instructions_by_shippingContainerIdService {
    if(!this._ds_get_instructions_by_shippingContainerId) {
      this._ds_get_instructions_by_shippingContainerId = this.injector.get(PackVerification_ds_get_instructions_by_shippingContainerIdService);
    }
    return this._ds_get_instructions_by_shippingContainerId;
  }
  private _ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId: PackVerification_ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId(): PackVerification_ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId) {
      this._ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId = this.injector.get(PackVerification_ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_licenseplatecontents_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_licenseplatecontents_by_shippingContainerId: PackVerification_ds_get_licenseplatecontents_by_shippingContainerIdService;
  public get ds_get_licenseplatecontents_by_shippingContainerId(): PackVerification_ds_get_licenseplatecontents_by_shippingContainerIdService {
    if(!this._ds_get_licenseplatecontents_by_shippingContainerId) {
      this._ds_get_licenseplatecontents_by_shippingContainerId = this.injector.get(PackVerification_ds_get_licenseplatecontents_by_shippingContainerIdService);
    }
    return this._ds_get_licenseplatecontents_by_shippingContainerId;
  }
  private _ds_get_licenseplates_by_shippingContainerId: PackVerification_ds_get_licenseplates_by_shippingContainerIdService;
  public get ds_get_licenseplates_by_shippingContainerId(): PackVerification_ds_get_licenseplates_by_shippingContainerIdService {
    if(!this._ds_get_licenseplates_by_shippingContainerId) {
      this._ds_get_licenseplates_by_shippingContainerId = this.injector.get(PackVerification_ds_get_licenseplates_by_shippingContainerIdService);
    }
    return this._ds_get_licenseplates_by_shippingContainerId;
  }
  private _ds_get_location_by_locationId: PackVerification_ds_get_location_by_locationIdService;
  public get ds_get_location_by_locationId(): PackVerification_ds_get_location_by_locationIdService {
    if(!this._ds_get_location_by_locationId) {
      this._ds_get_location_by_locationId = this.injector.get(PackVerification_ds_get_location_by_locationIdService);
    }
    return this._ds_get_location_by_locationId;
  }
  private _ds_get_material_packaging_lookup_by_materialId_packagingId: PackVerification_ds_get_material_packaging_lookup_by_materialId_packagingIdService;
  public get ds_get_material_packaging_lookup_by_materialId_packagingId(): PackVerification_ds_get_material_packaging_lookup_by_materialId_packagingIdService {
    if(!this._ds_get_material_packaging_lookup_by_materialId_packagingId) {
      this._ds_get_material_packaging_lookup_by_materialId_packagingId = this.injector.get(PackVerification_ds_get_material_packaging_lookup_by_materialId_packagingIdService);
    }
    return this._ds_get_material_packaging_lookup_by_materialId_packagingId;
  }
  private _ds_get_open_start_end_pack_verification_task_by_shippingContainerId: PackVerification_ds_get_open_start_end_pack_verification_task_by_shippingContainerIdService;
  public get ds_get_open_start_end_pack_verification_task_by_shippingContainerId(): PackVerification_ds_get_open_start_end_pack_verification_task_by_shippingContainerIdService {
    if(!this._ds_get_open_start_end_pack_verification_task_by_shippingContainerId) {
      this._ds_get_open_start_end_pack_verification_task_by_shippingContainerId = this.injector.get(PackVerification_ds_get_open_start_end_pack_verification_task_by_shippingContainerIdService);
    }
    return this._ds_get_open_start_end_pack_verification_task_by_shippingContainerId;
  }
  private _ds_get_operationcode_by_operationCodeName_top1: PackVerification_ds_get_operationcode_by_operationCodeName_top1Service;
  public get ds_get_operationcode_by_operationCodeName_top1(): PackVerification_ds_get_operationcode_by_operationCodeName_top1Service {
    if(!this._ds_get_operationcode_by_operationCodeName_top1) {
      this._ds_get_operationcode_by_operationCodeName_top1 = this.injector.get(PackVerification_ds_get_operationcode_by_operationCodeName_top1Service);
    }
    return this._ds_get_operationcode_by_operationCodeName_top1;
  }
  private _ds_get_pack_verification_tasks_by_content: PackVerification_ds_get_pack_verification_tasks_by_contentService;
  public get ds_get_pack_verification_tasks_by_content(): PackVerification_ds_get_pack_verification_tasks_by_contentService {
    if(!this._ds_get_pack_verification_tasks_by_content) {
      this._ds_get_pack_verification_tasks_by_content = this.injector.get(PackVerification_ds_get_pack_verification_tasks_by_contentService);
    }
    return this._ds_get_pack_verification_tasks_by_content;
  }
  private _ds_get_pack_verification_tasks_by_shippingContainerId: PackVerification_ds_get_pack_verification_tasks_by_shippingContainerIdService;
  public get ds_get_pack_verification_tasks_by_shippingContainerId(): PackVerification_ds_get_pack_verification_tasks_by_shippingContainerIdService {
    if(!this._ds_get_pack_verification_tasks_by_shippingContainerId) {
      this._ds_get_pack_verification_tasks_by_shippingContainerId = this.injector.get(PackVerification_ds_get_pack_verification_tasks_by_shippingContainerIdService);
    }
    return this._ds_get_pack_verification_tasks_by_shippingContainerId;
  }
  private _ds_get_pick_slip_by_pickSlipId: PackVerification_ds_get_pick_slip_by_pickSlipIdService;
  public get ds_get_pick_slip_by_pickSlipId(): PackVerification_ds_get_pick_slip_by_pickSlipIdService {
    if(!this._ds_get_pick_slip_by_pickSlipId) {
      this._ds_get_pick_slip_by_pickSlipId = this.injector.get(PackVerification_ds_get_pick_slip_by_pickSlipIdService);
    }
    return this._ds_get_pick_slip_by_pickSlipId;
  }
  private _ds_get_picking_tasks_by_licenseplateId: PackVerification_ds_get_picking_tasks_by_licenseplateIdService;
  public get ds_get_picking_tasks_by_licenseplateId(): PackVerification_ds_get_picking_tasks_by_licenseplateIdService {
    if(!this._ds_get_picking_tasks_by_licenseplateId) {
      this._ds_get_picking_tasks_by_licenseplateId = this.injector.get(PackVerification_ds_get_picking_tasks_by_licenseplateIdService);
    }
    return this._ds_get_picking_tasks_by_licenseplateId;
  }
  private _ds_get_reasoncode_by_reasonCodeId: PackVerification_ds_get_reasoncode_by_reasonCodeIdService;
  public get ds_get_reasoncode_by_reasonCodeId(): PackVerification_ds_get_reasoncode_by_reasonCodeIdService {
    if(!this._ds_get_reasoncode_by_reasonCodeId) {
      this._ds_get_reasoncode_by_reasonCodeId = this.injector.get(PackVerification_ds_get_reasoncode_by_reasonCodeIdService);
    }
    return this._ds_get_reasoncode_by_reasonCodeId;
  }
  private _ds_get_serialnumber_by_serialNumberCode: PackVerification_ds_get_serialnumber_by_serialNumberCodeService;
  public get ds_get_serialnumber_by_serialNumberCode(): PackVerification_ds_get_serialnumber_by_serialNumberCodeService {
    if(!this._ds_get_serialnumber_by_serialNumberCode) {
      this._ds_get_serialnumber_by_serialNumberCode = this.injector.get(PackVerification_ds_get_serialnumber_by_serialNumberCodeService);
    }
    return this._ds_get_serialnumber_by_serialNumberCode;
  }
  private _ds_get_serialnumber_by_serialNumberId: PackVerification_ds_get_serialnumber_by_serialNumberIdService;
  public get ds_get_serialnumber_by_serialNumberId(): PackVerification_ds_get_serialnumber_by_serialNumberIdService {
    if(!this._ds_get_serialnumber_by_serialNumberId) {
      this._ds_get_serialnumber_by_serialNumberId = this.injector.get(PackVerification_ds_get_serialnumber_by_serialNumberIdService);
    }
    return this._ds_get_serialnumber_by_serialNumberId;
  }
  private _ds_get_serialnumber_by_shippingContainerId_serialNumberCode: PackVerification_ds_get_serialnumber_by_shippingContainerId_serialNumberCodeService;
  public get ds_get_serialnumber_by_shippingContainerId_serialNumberCode(): PackVerification_ds_get_serialnumber_by_shippingContainerId_serialNumberCodeService {
    if(!this._ds_get_serialnumber_by_shippingContainerId_serialNumberCode) {
      this._ds_get_serialnumber_by_shippingContainerId_serialNumberCode = this.injector.get(PackVerification_ds_get_serialnumber_by_shippingContainerId_serialNumberCodeService);
    }
    return this._ds_get_serialnumber_by_shippingContainerId_serialNumberCode;
  }
  private _ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId: PackVerification_ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId(): PackVerification_ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId) {
      this._ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId = this.injector.get(PackVerification_ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_serialnumbermove_tasks_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_serialnumbers_by_shippingContainerId: PackVerification_ds_get_serialnumbers_by_shippingContainerIdService;
  public get ds_get_serialnumbers_by_shippingContainerId(): PackVerification_ds_get_serialnumbers_by_shippingContainerIdService {
    if(!this._ds_get_serialnumbers_by_shippingContainerId) {
      this._ds_get_serialnumbers_by_shippingContainerId = this.injector.get(PackVerification_ds_get_serialnumbers_by_shippingContainerIdService);
    }
    return this._ds_get_serialnumbers_by_shippingContainerId;
  }
  private _ds_get_shipping_containers_by_shipmentId: PackVerification_ds_get_shipping_containers_by_shipmentIdService;
  public get ds_get_shipping_containers_by_shipmentId(): PackVerification_ds_get_shipping_containers_by_shipmentIdService {
    if(!this._ds_get_shipping_containers_by_shipmentId) {
      this._ds_get_shipping_containers_by_shipmentId = this.injector.get(PackVerification_ds_get_shipping_containers_by_shipmentIdService);
    }
    return this._ds_get_shipping_containers_by_shipmentId;
  }
  private _ds_get_shipping_containers_not_verified_by_shipmentId: PackVerification_ds_get_shipping_containers_not_verified_by_shipmentIdService;
  public get ds_get_shipping_containers_not_verified_by_shipmentId(): PackVerification_ds_get_shipping_containers_not_verified_by_shipmentIdService {
    if(!this._ds_get_shipping_containers_not_verified_by_shipmentId) {
      this._ds_get_shipping_containers_not_verified_by_shipmentId = this.injector.get(PackVerification_ds_get_shipping_containers_not_verified_by_shipmentIdService);
    }
    return this._ds_get_shipping_containers_not_verified_by_shipmentId;
  }
  private _ds_get_shippingcontainer_by_shippingContainerCode: PackVerification_ds_get_shippingcontainer_by_shippingContainerCodeService;
  public get ds_get_shippingcontainer_by_shippingContainerCode(): PackVerification_ds_get_shippingcontainer_by_shippingContainerCodeService {
    if(!this._ds_get_shippingcontainer_by_shippingContainerCode) {
      this._ds_get_shippingcontainer_by_shippingContainerCode = this.injector.get(PackVerification_ds_get_shippingcontainer_by_shippingContainerCodeService);
    }
    return this._ds_get_shippingcontainer_by_shippingContainerCode;
  }
  private _ds_get_shippingcontainer_by_shippingContainerId: PackVerification_ds_get_shippingcontainer_by_shippingContainerIdService;
  public get ds_get_shippingcontainer_by_shippingContainerId(): PackVerification_ds_get_shippingcontainer_by_shippingContainerIdService {
    if(!this._ds_get_shippingcontainer_by_shippingContainerId) {
      this._ds_get_shippingcontainer_by_shippingContainerId = this.injector.get(PackVerification_ds_get_shippingcontainer_by_shippingContainerIdService);
    }
    return this._ds_get_shippingcontainer_by_shippingContainerId;
  }
  private _ds_get_shippingcontainer_content: PackVerification_ds_get_shippingcontainer_contentService;
  public get ds_get_shippingcontainer_content(): PackVerification_ds_get_shippingcontainer_contentService {
    if(!this._ds_get_shippingcontainer_content) {
      this._ds_get_shippingcontainer_content = this.injector.get(PackVerification_ds_get_shippingcontainer_contentService);
    }
    return this._ds_get_shippingcontainer_content;
  }
  private _ds_get_shippingcontainer_instructions: PackVerification_ds_get_shippingcontainer_instructionsService;
  public get ds_get_shippingcontainer_instructions(): PackVerification_ds_get_shippingcontainer_instructionsService {
    if(!this._ds_get_shippingcontainer_instructions) {
      this._ds_get_shippingcontainer_instructions = this.injector.get(PackVerification_ds_get_shippingcontainer_instructionsService);
    }
    return this._ds_get_shippingcontainer_instructions;
  }
  private _ds_get_shippingcontainers_by_warehouseId: PackVerification_ds_get_shippingcontainers_by_warehouseIdService;
  public get ds_get_shippingcontainers_by_warehouseId(): PackVerification_ds_get_shippingcontainers_by_warehouseIdService {
    if(!this._ds_get_shippingcontainers_by_warehouseId) {
      this._ds_get_shippingcontainers_by_warehouseId = this.injector.get(PackVerification_ds_get_shippingcontainers_by_warehouseIdService);
    }
    return this._ds_get_shippingcontainers_by_warehouseId;
  }
  private _ds_get_shippingcontents_by_licenseplateId_lotId_packagingId: PackVerification_ds_get_shippingcontents_by_licenseplateId_lotId_packagingIdService;
  public get ds_get_shippingcontents_by_licenseplateId_lotId_packagingId(): PackVerification_ds_get_shippingcontents_by_licenseplateId_lotId_packagingIdService {
    if(!this._ds_get_shippingcontents_by_licenseplateId_lotId_packagingId) {
      this._ds_get_shippingcontents_by_licenseplateId_lotId_packagingId = this.injector.get(PackVerification_ds_get_shippingcontents_by_licenseplateId_lotId_packagingIdService);
    }
    return this._ds_get_shippingcontents_by_licenseplateId_lotId_packagingId;
  }
  private _ds_get_start_pack_verification_task_by_taskId: PackVerification_ds_get_start_pack_verification_task_by_taskIdService;
  public get ds_get_start_pack_verification_task_by_taskId(): PackVerification_ds_get_start_pack_verification_task_by_taskIdService {
    if(!this._ds_get_start_pack_verification_task_by_taskId) {
      this._ds_get_start_pack_verification_task_by_taskId = this.injector.get(PackVerification_ds_get_start_pack_verification_task_by_taskIdService);
    }
    return this._ds_get_start_pack_verification_task_by_taskId;
  }
  private _ds_get_start_pack_verification_tasks_by_shippingContainerId: PackVerification_ds_get_start_pack_verification_tasks_by_shippingContainerIdService;
  public get ds_get_start_pack_verification_tasks_by_shippingContainerId(): PackVerification_ds_get_start_pack_verification_tasks_by_shippingContainerIdService {
    if(!this._ds_get_start_pack_verification_tasks_by_shippingContainerId) {
      this._ds_get_start_pack_verification_tasks_by_shippingContainerId = this.injector.get(PackVerification_ds_get_start_pack_verification_tasks_by_shippingContainerIdService);
    }
    return this._ds_get_start_pack_verification_tasks_by_shippingContainerId;
  }
  private _ds_get_task_by_taskId: PackVerification_ds_get_task_by_taskIdService;
  public get ds_get_task_by_taskId(): PackVerification_ds_get_task_by_taskIdService {
    if(!this._ds_get_task_by_taskId) {
      this._ds_get_task_by_taskId = this.injector.get(PackVerification_ds_get_task_by_taskIdService);
    }
    return this._ds_get_task_by_taskId;
  }
  private _ds_get_unassigned_pack_verification_tasks_by_shippingContainerId: PackVerification_ds_get_unassigned_pack_verification_tasks_by_shippingContainerIdService;
  public get ds_get_unassigned_pack_verification_tasks_by_shippingContainerId(): PackVerification_ds_get_unassigned_pack_verification_tasks_by_shippingContainerIdService {
    if(!this._ds_get_unassigned_pack_verification_tasks_by_shippingContainerId) {
      this._ds_get_unassigned_pack_verification_tasks_by_shippingContainerId = this.injector.get(PackVerification_ds_get_unassigned_pack_verification_tasks_by_shippingContainerIdService);
    }
    return this._ds_get_unassigned_pack_verification_tasks_by_shippingContainerId;
  }
  private _ds_get_upc_by_materal_ids: PackVerification_ds_get_upc_by_materal_idsService;
  public get ds_get_upc_by_materal_ids(): PackVerification_ds_get_upc_by_materal_idsService {
    if(!this._ds_get_upc_by_materal_ids) {
      this._ds_get_upc_by_materal_ids = this.injector.get(PackVerification_ds_get_upc_by_materal_idsService);
    }
    return this._ds_get_upc_by_materal_ids;
  }
  private _ds_measurementunits_dd: PackVerification_ds_measurementunits_ddService;
  public get ds_measurementunits_dd(): PackVerification_ds_measurementunits_ddService {
    if(!this._ds_measurementunits_dd) {
      this._ds_measurementunits_dd = this.injector.get(PackVerification_ds_measurementunits_ddService);
    }
    return this._ds_measurementunits_dd;
  }
  private _ds_pack_verification_shipping_container_contents_grid: PackVerification_ds_pack_verification_shipping_container_contents_gridService;
  public get ds_pack_verification_shipping_container_contents_grid(): PackVerification_ds_pack_verification_shipping_container_contents_gridService {
    if(!this._ds_pack_verification_shipping_container_contents_grid) {
      this._ds_pack_verification_shipping_container_contents_grid = this.injector.get(PackVerification_ds_pack_verification_shipping_container_contents_gridService);
    }
    return this._ds_pack_verification_shipping_container_contents_grid;
  }
  private _ds_pack_verification_verify_container_editor: PackVerification_ds_pack_verification_verify_container_editorService;
  public get ds_pack_verification_verify_container_editor(): PackVerification_ds_pack_verification_verify_container_editorService {
    if(!this._ds_pack_verification_verify_container_editor) {
      this._ds_pack_verification_verify_container_editor = this.injector.get(PackVerification_ds_pack_verification_verify_container_editorService);
    }
    return this._ds_pack_verification_verify_container_editor;
  }
  private _ds_reasoncodes_dd: PackVerification_ds_reasoncodes_ddService;
  public get ds_reasoncodes_dd(): PackVerification_ds_reasoncodes_ddService {
    if(!this._ds_reasoncodes_dd) {
      this._ds_reasoncodes_dd = this.injector.get(PackVerification_ds_reasoncodes_ddService);
    }
    return this._ds_reasoncodes_dd;
  }
  private _ds_scanned_values_grid: PackVerification_ds_scanned_values_gridService;
  public get ds_scanned_values_grid(): PackVerification_ds_scanned_values_gridService {
    if(!this._ds_scanned_values_grid) {
      this._ds_scanned_values_grid = this.injector.get(PackVerification_ds_scanned_values_gridService);
    }
    return this._ds_scanned_values_grid;
  }
  private _ds_shipping_container_pack_verification_tasks_grid: PackVerification_ds_shipping_container_pack_verification_tasks_gridService;
  public get ds_shipping_container_pack_verification_tasks_grid(): PackVerification_ds_shipping_container_pack_verification_tasks_gridService {
    if(!this._ds_shipping_container_pack_verification_tasks_grid) {
      this._ds_shipping_container_pack_verification_tasks_grid = this.injector.get(PackVerification_ds_shipping_container_pack_verification_tasks_gridService);
    }
    return this._ds_shipping_container_pack_verification_tasks_grid;
  }
  private _ds_shippingcontainers_failed_verification_grid: PackVerification_ds_shippingcontainers_failed_verification_gridService;
  public get ds_shippingcontainers_failed_verification_grid(): PackVerification_ds_shippingcontainers_failed_verification_gridService {
    if(!this._ds_shippingcontainers_failed_verification_grid) {
      this._ds_shippingcontainers_failed_verification_grid = this.injector.get(PackVerification_ds_shippingcontainers_failed_verification_gridService);
    }
    return this._ds_shippingcontainers_failed_verification_grid;
  }
  private _ds_shippingcontainers_failed_verification_widget: PackVerification_ds_shippingcontainers_failed_verification_widgetService;
  public get ds_shippingcontainers_failed_verification_widget(): PackVerification_ds_shippingcontainers_failed_verification_widgetService {
    if(!this._ds_shippingcontainers_failed_verification_widget) {
      this._ds_shippingcontainers_failed_verification_widget = this.injector.get(PackVerification_ds_shippingcontainers_failed_verification_widgetService);
    }
    return this._ds_shippingcontainers_failed_verification_widget;
  }
  private _ds_shippingcontainers_to_verify_grid: PackVerification_ds_shippingcontainers_to_verify_gridService;
  public get ds_shippingcontainers_to_verify_grid(): PackVerification_ds_shippingcontainers_to_verify_gridService {
    if(!this._ds_shippingcontainers_to_verify_grid) {
      this._ds_shippingcontainers_to_verify_grid = this.injector.get(PackVerification_ds_shippingcontainers_to_verify_gridService);
    }
    return this._ds_shippingcontainers_to_verify_grid;
  }
  private _ds_shippingcontainers_to_verify_widget: PackVerification_ds_shippingcontainers_to_verify_widgetService;
  public get ds_shippingcontainers_to_verify_widget(): PackVerification_ds_shippingcontainers_to_verify_widgetService {
    if(!this._ds_shippingcontainers_to_verify_widget) {
      this._ds_shippingcontainers_to_verify_widget = this.injector.get(PackVerification_ds_shippingcontainers_to_verify_widgetService);
    }
    return this._ds_shippingcontainers_to_verify_widget;
  }
  private _ds_verification_locations_dd: PackVerification_ds_verification_locations_ddService;
  public get ds_verification_locations_dd(): PackVerification_ds_verification_locations_ddService {
    if(!this._ds_verification_locations_dd) {
      this._ds_verification_locations_dd = this.injector.get(PackVerification_ds_verification_locations_ddService);
    }
    return this._ds_verification_locations_dd;
  }
}

