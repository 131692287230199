import { Inject, Injectable, Injector } from '@angular/core';


import { WarehouseTransfers_add_licenseplates_from_outbound_to_inbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_adjust_target_inbound_order_orderline_and_shipmentline_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_auto_fulfill_warehouse_transfer_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_auto_load_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_auto_pick_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_auto_receive_target_inbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_auto_receive_target_inbound_task_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_cancel_manual_allocation_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_cancel_pick_task_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_cancel_warehouse_transfer_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_complete_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_complete_target_inbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_create_or_update_warehouse_transfer_line_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_create_warehouse_transfer_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_create_warehouse_transfer_from_completed_inbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_create_wave_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_delete_warehouse_transfer_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_delete_warehouse_transfer_line_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_get_outbound_order_line_availability_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_get_warehouse_transfer_status_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_is_manual_allocation_cancelable_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_is_outbound_order_line_deletable_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_is_pick_task_cancelable_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_is_warehouse_transfer_deletableService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_pick_on_licenseplate_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_process_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_process_target_inbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_process_wave_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_reallocate_pick_task_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_release_wave_source_outbound_order_flowService } from './WarehouseTransfers.flow.index';
import { WarehouseTransfers_set_target_inbound_receiving_task_flowService } from './WarehouseTransfers.flow.index';

import { $frontendTypes } from './WarehouseTransfers.frontend.types'

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public WarehouseTransfers: WarehouseTransfers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _add_licenseplates_from_outbound_to_inbound_order_flow: WarehouseTransfers_add_licenseplates_from_outbound_to_inbound_order_flowService;
  public async add_licenseplates_from_outbound_to_inbound_order_flow(inParams: { warehouse_transfer_id: number }): Promise< { reasons?: string[] }> {
    if(!this._add_licenseplates_from_outbound_to_inbound_order_flow) {
      this._add_licenseplates_from_outbound_to_inbound_order_flow = this.injector.get(WarehouseTransfers_add_licenseplates_from_outbound_to_inbound_order_flowService);
    }
    return this._add_licenseplates_from_outbound_to_inbound_order_flow.run(inParams);
  }
   
   

   
 
  private _adjust_target_inbound_order_orderline_and_shipmentline_flow: WarehouseTransfers_adjust_target_inbound_order_orderline_and_shipmentline_flowService;
  public async adjust_target_inbound_order_orderline_and_shipmentline_flow(inParams: { adjust_packaged_amount: number, shipment_line_id: number, order_id: number, order_line_number: number }): Promise< { reasons?: string[] }> {
    if(!this._adjust_target_inbound_order_orderline_and_shipmentline_flow) {
      this._adjust_target_inbound_order_orderline_and_shipmentline_flow = this.injector.get(WarehouseTransfers_adjust_target_inbound_order_orderline_and_shipmentline_flowService);
    }
    return this._adjust_target_inbound_order_orderline_and_shipmentline_flow.run(inParams);
  }
   
   

   
 
  private _auto_fulfill_warehouse_transfer_flow: WarehouseTransfers_auto_fulfill_warehouse_transfer_flowService;
  public async auto_fulfill_warehouse_transfer_flow(inParams: { warehouse_transfer_id: number }): Promise< { reasons?: string[] }> {
    if(!this._auto_fulfill_warehouse_transfer_flow) {
      this._auto_fulfill_warehouse_transfer_flow = this.injector.get(WarehouseTransfers_auto_fulfill_warehouse_transfer_flowService);
    }
    return this._auto_fulfill_warehouse_transfer_flow.run(inParams);
  }
   
   

   
 
  private _auto_load_source_outbound_order_flow: WarehouseTransfers_auto_load_source_outbound_order_flowService;
  public async auto_load_source_outbound_order_flow(inParams: { order_id: number, load_location_id?: number }): Promise< { reasons?: string[] }> {
    if(!this._auto_load_source_outbound_order_flow) {
      this._auto_load_source_outbound_order_flow = this.injector.get(WarehouseTransfers_auto_load_source_outbound_order_flowService);
    }
    return this._auto_load_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _auto_pick_source_outbound_order_flow: WarehouseTransfers_auto_pick_source_outbound_order_flowService;
  public async auto_pick_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._auto_pick_source_outbound_order_flow) {
      this._auto_pick_source_outbound_order_flow = this.injector.get(WarehouseTransfers_auto_pick_source_outbound_order_flowService);
    }
    return this._auto_pick_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _auto_receive_target_inbound_order_flow: WarehouseTransfers_auto_receive_target_inbound_order_flowService;
  public async auto_receive_target_inbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._auto_receive_target_inbound_order_flow) {
      this._auto_receive_target_inbound_order_flow = this.injector.get(WarehouseTransfers_auto_receive_target_inbound_order_flowService);
    }
    return this._auto_receive_target_inbound_order_flow.run(inParams);
  }
   
   

   
 
  private _auto_receive_target_inbound_task_flow: WarehouseTransfers_auto_receive_target_inbound_task_flowService;
  public async auto_receive_target_inbound_task_flow(inParams: { taskId: number }): Promise<void> {
    if(!this._auto_receive_target_inbound_task_flow) {
      this._auto_receive_target_inbound_task_flow = this.injector.get(WarehouseTransfers_auto_receive_target_inbound_task_flowService);
    }
    return this._auto_receive_target_inbound_task_flow.run(inParams);
  }
   
   

   
 
  private _cancel_manual_allocation_flow: WarehouseTransfers_cancel_manual_allocation_flowService;
  public async cancel_manual_allocation_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._cancel_manual_allocation_flow) {
      this._cancel_manual_allocation_flow = this.injector.get(WarehouseTransfers_cancel_manual_allocation_flowService);
    }
    return this._cancel_manual_allocation_flow.run(inParams);
  }
   
   

   
 
  private _cancel_pick_task_flow: WarehouseTransfers_cancel_pick_task_flowService;
  public async cancel_pick_task_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._cancel_pick_task_flow) {
      this._cancel_pick_task_flow = this.injector.get(WarehouseTransfers_cancel_pick_task_flowService);
    }
    return this._cancel_pick_task_flow.run(inParams);
  }
   
   

   
 
  private _cancel_warehouse_transfer_flow: WarehouseTransfers_cancel_warehouse_transfer_flowService;
  public async cancel_warehouse_transfer_flow(inParams: { warehouseTransferId: number }): Promise< { reasons?: string[] }> {
    if(!this._cancel_warehouse_transfer_flow) {
      this._cancel_warehouse_transfer_flow = this.injector.get(WarehouseTransfers_cancel_warehouse_transfer_flowService);
    }
    return this._cancel_warehouse_transfer_flow.run(inParams);
  }
   
   

   
 
  private _complete_source_outbound_order_flow: WarehouseTransfers_complete_source_outbound_order_flowService;
  public async complete_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_source_outbound_order_flow) {
      this._complete_source_outbound_order_flow = this.injector.get(WarehouseTransfers_complete_source_outbound_order_flowService);
    }
    return this._complete_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _complete_target_inbound_order_flow: WarehouseTransfers_complete_target_inbound_order_flowService;
  public async complete_target_inbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_target_inbound_order_flow) {
      this._complete_target_inbound_order_flow = this.injector.get(WarehouseTransfers_complete_target_inbound_order_flowService);
    }
    return this._complete_target_inbound_order_flow.run(inParams);
  }
   
   

   
 
  private _create_or_update_warehouse_transfer_line_flow: WarehouseTransfers_create_or_update_warehouse_transfer_line_flowService;
  public async create_or_update_warehouse_transfer_line_flow(inParams: { line_number?: number, warehouse_transfer_id: number, material_id: number, vendor_lot_id?: number, lot_id?: number, packaging_id: number, packaged_amount: number, license_plate_id?: number, serial_number_id?: number }): Promise< { reasons?: string[], lineNumber?: number }> {
    if(!this._create_or_update_warehouse_transfer_line_flow) {
      this._create_or_update_warehouse_transfer_line_flow = this.injector.get(WarehouseTransfers_create_or_update_warehouse_transfer_line_flowService);
    }
    return this._create_or_update_warehouse_transfer_line_flow.run(inParams);
  }
   
   

   
 
  private _create_warehouse_transfer_flow: WarehouseTransfers_create_warehouse_transfer_flowService;
  public async create_warehouse_transfer_flow(inParams: { project_id: number, source_warehouse_id: number, target_warehouse_id: number, notes?: string, lookup?: string, outbound_order_lookup?: string, inbound_order_lookup?: string }): Promise< { warehouse_transfer_id?: number, outbound_order_id?: number, inbound_order_id?: number, outbound_shipment_id?: number, inbound_shipment_id?: number, reasons?: string[] }> {
    if(!this._create_warehouse_transfer_flow) {
      this._create_warehouse_transfer_flow = this.injector.get(WarehouseTransfers_create_warehouse_transfer_flowService);
    }
    return this._create_warehouse_transfer_flow.run(inParams);
  }
   
   

   
 
  private _create_warehouse_transfer_from_completed_inbound_order_flow: WarehouseTransfers_create_warehouse_transfer_from_completed_inbound_order_flowService;
  public async create_warehouse_transfer_from_completed_inbound_order_flow(inParams: { order_id: number, target_warehouse_id: number }): Promise< { reasons?: string[], warehouse_transfer_id?: number }> {
    if(!this._create_warehouse_transfer_from_completed_inbound_order_flow) {
      this._create_warehouse_transfer_from_completed_inbound_order_flow = this.injector.get(WarehouseTransfers_create_warehouse_transfer_from_completed_inbound_order_flowService);
    }
    return this._create_warehouse_transfer_from_completed_inbound_order_flow.run(inParams);
  }
   
   

   
 
  private _create_wave_source_outbound_order_flow: WarehouseTransfers_create_wave_source_outbound_order_flowService;
  public async create_wave_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._create_wave_source_outbound_order_flow) {
      this._create_wave_source_outbound_order_flow = this.injector.get(WarehouseTransfers_create_wave_source_outbound_order_flowService);
    }
    return this._create_wave_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _delete_warehouse_transfer_flow: WarehouseTransfers_delete_warehouse_transfer_flowService;
  public async delete_warehouse_transfer_flow(inParams: { warehouseTransferId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_warehouse_transfer_flow) {
      this._delete_warehouse_transfer_flow = this.injector.get(WarehouseTransfers_delete_warehouse_transfer_flowService);
    }
    return this._delete_warehouse_transfer_flow.run(inParams);
  }
   
   

   
 
  private _delete_warehouse_transfer_line_flow: WarehouseTransfers_delete_warehouse_transfer_line_flowService;
  public async delete_warehouse_transfer_line_flow(inParams: { warehouseTransferId: number, lineNumber: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_warehouse_transfer_line_flow) {
      this._delete_warehouse_transfer_line_flow = this.injector.get(WarehouseTransfers_delete_warehouse_transfer_line_flowService);
    }
    return this._delete_warehouse_transfer_line_flow.run(inParams);
  }
   
   

   
 
  private _get_outbound_order_line_availability_flow: WarehouseTransfers_get_outbound_order_line_availability_flowService;
  public async get_outbound_order_line_availability_flow(inParams: { warehouseId: number, materialId?: number, packagedId: number, lotId?: number, licenseplateId?: number, serialnumberId?: number, locationId?: number }): Promise< { availableByMaterial?: number, availableByLot?: number, availableByLicenseplate?: number, availableBySerialnumber?: number, availableHelper?: string, availableByLocation?: number }> {
    if(!this._get_outbound_order_line_availability_flow) {
      this._get_outbound_order_line_availability_flow = this.injector.get(WarehouseTransfers_get_outbound_order_line_availability_flowService);
    }
    return this._get_outbound_order_line_availability_flow.run(inParams);
  }
   
   

   
 
  private _get_warehouse_transfer_status_flow: WarehouseTransfers_get_warehouse_transfer_status_flowService;
  public async get_warehouse_transfer_status_flow(inParams: { warehouse_transfer_id: number }): Promise< { reasons?: string[], status_name?: string, state_description?: string, status_id?: number, state?: number, eligibilities?: { is_outbound_processable?: boolean, is_outbound_pickable?: boolean, is_outbound_loadable?: boolean, is_outbound_completable?: boolean, is_inbound_processable?: boolean, is_inbound_receivable?: boolean, is_inbound_completable?: boolean, is_transfer_cancellable?: boolean }, details?: { has_manual_allocations?: boolean } }> {
    if(!this._get_warehouse_transfer_status_flow) {
      this._get_warehouse_transfer_status_flow = this.injector.get(WarehouseTransfers_get_warehouse_transfer_status_flowService);
    }
    return this._get_warehouse_transfer_status_flow.run(inParams);
  }
   
   

   
 
  private _is_manual_allocation_cancelable_flow: WarehouseTransfers_is_manual_allocation_cancelable_flowService;
  public async is_manual_allocation_cancelable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_manual_allocation_cancelable_flow) {
      this._is_manual_allocation_cancelable_flow = this.injector.get(WarehouseTransfers_is_manual_allocation_cancelable_flowService);
    }
    return this._is_manual_allocation_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_outbound_order_line_deletable_flow: WarehouseTransfers_is_outbound_order_line_deletable_flowService;
  public async is_outbound_order_line_deletable_flow(inParams: { orderId: number, lineNumber: number }): Promise< { reason?: string }> {
    if(!this._is_outbound_order_line_deletable_flow) {
      this._is_outbound_order_line_deletable_flow = this.injector.get(WarehouseTransfers_is_outbound_order_line_deletable_flowService);
    }
    return this._is_outbound_order_line_deletable_flow.run(inParams);
  }
   
   

   
 
  private _is_pick_task_cancelable_flow: WarehouseTransfers_is_pick_task_cancelable_flowService;
  public async is_pick_task_cancelable_flow(inParams: { taskId: number }): Promise< { reason?: string }> {
    if(!this._is_pick_task_cancelable_flow) {
      this._is_pick_task_cancelable_flow = this.injector.get(WarehouseTransfers_is_pick_task_cancelable_flowService);
    }
    return this._is_pick_task_cancelable_flow.run(inParams);
  }
   
   

   
 
  private _is_warehouse_transfer_deletable: WarehouseTransfers_is_warehouse_transfer_deletableService;
  public async is_warehouse_transfer_deletable(inParams: { warehouseTransferId: number, optimalInputs?: { statusId?: number } }): Promise< { reasons?: string[], isValid?: boolean }> {
    if(!this._is_warehouse_transfer_deletable) {
      this._is_warehouse_transfer_deletable = this.injector.get(WarehouseTransfers_is_warehouse_transfer_deletableService);
    }
    return this._is_warehouse_transfer_deletable.run(inParams);
  }
   
   

   
 
  private _pick_on_licenseplate_flow: WarehouseTransfers_pick_on_licenseplate_flowService;
  public async pick_on_licenseplate_flow(inParams: { taskId: number, packagedAmount: number, packagingId: number, sourceLicensePlateId: number, targetLicensePlateLookupCode?: string, targetLocationId?: number, serialNumberIds?: number[], targetLicensePlateId?: number }): Promise< { reasons?: string[] }> {
    if(!this._pick_on_licenseplate_flow) {
      this._pick_on_licenseplate_flow = this.injector.get(WarehouseTransfers_pick_on_licenseplate_flowService);
    }
    return this._pick_on_licenseplate_flow.run(inParams);
  }
   
   

   
 
  private _process_source_outbound_order_flow: WarehouseTransfers_process_source_outbound_order_flowService;
  public async process_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._process_source_outbound_order_flow) {
      this._process_source_outbound_order_flow = this.injector.get(WarehouseTransfers_process_source_outbound_order_flowService);
    }
    return this._process_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _process_target_inbound_order_flow: WarehouseTransfers_process_target_inbound_order_flowService;
  public async process_target_inbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._process_target_inbound_order_flow) {
      this._process_target_inbound_order_flow = this.injector.get(WarehouseTransfers_process_target_inbound_order_flowService);
    }
    return this._process_target_inbound_order_flow.run(inParams);
  }
   
   

   
 
  private _process_wave_source_outbound_order_flow: WarehouseTransfers_process_wave_source_outbound_order_flowService;
  public async process_wave_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._process_wave_source_outbound_order_flow) {
      this._process_wave_source_outbound_order_flow = this.injector.get(WarehouseTransfers_process_wave_source_outbound_order_flowService);
    }
    return this._process_wave_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _reallocate_pick_task_flow: WarehouseTransfers_reallocate_pick_task_flowService;
  public async reallocate_pick_task_flow(inParams: { taskId: number, lotId: number, locationId: number, vendorLotId: number, currentPackagedAmount: number, updatedPackagedAmount: number }): Promise< { reason?: string }> {
    if(!this._reallocate_pick_task_flow) {
      this._reallocate_pick_task_flow = this.injector.get(WarehouseTransfers_reallocate_pick_task_flowService);
    }
    return this._reallocate_pick_task_flow.run(inParams);
  }
   
   

   
 
  private _release_wave_source_outbound_order_flow: WarehouseTransfers_release_wave_source_outbound_order_flowService;
  public async release_wave_source_outbound_order_flow(inParams: { order_id: number }): Promise< { reasons?: string[] }> {
    if(!this._release_wave_source_outbound_order_flow) {
      this._release_wave_source_outbound_order_flow = this.injector.get(WarehouseTransfers_release_wave_source_outbound_order_flowService);
    }
    return this._release_wave_source_outbound_order_flow.run(inParams);
  }
   
   

   
 
  private _set_target_inbound_receiving_task_flow: WarehouseTransfers_set_target_inbound_receiving_task_flowService;
  public async set_target_inbound_receiving_task_flow(inParams: { license_plate_id: number, lot_id: number, packaging_id: number, order_id: number, material_id: number, vendor_lot_id: number, packaged_amount: number, existing_task_id: number, serial_numbers_to_create?: { DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number }[], serial_number_ids_to_delete?: number[] }): Promise< { task_id?: number, reasons?: string[] }> {
    if(!this._set_target_inbound_receiving_task_flow) {
      this._set_target_inbound_receiving_task_flow = this.injector.get(WarehouseTransfers_set_target_inbound_receiving_task_flowService);
    }
    return this._set_target_inbound_receiving_task_flow.run(inParams);
  }
   
   

   
}
