import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_ds_Outbound_LabelsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { LookupCode?: string }): 
  Promise<{ result: { Id?: number, LookupCode?: string, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string, Enterprise?: { Id?: number, Name?: string, Warehouses?: { Name?: string, Address?: { City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } }[] } } }, Account?: { LookupCode?: string, Name?: string, AccountsContactsLookup?: { AccountId?: number, ContactId?: number, Contact?: { AddressId?: number, FirstName?: string, LastName?: string, Title?: string, Address?: { City?: string, Country?: string, Line1?: string, PostalCode?: string, State?: string } } }[] }, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, LookupCode?: string, Carrier?: { Name?: string, ScacCode?: string, ShortName?: string }, LoadContainer?: { LookupCode?: string } } }[], first7fromproj?: string, CustomerPOwithAIcodeinfront400?: string, First4charactersofPO?: string } }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/ds_Outbound_Labels/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
