import { Inject, Injectable, Injector } from '@angular/core';


import { PrintNode_print_request_flowService } from './PrintNode.flow.index';
import { PrintNode_validate_credentials_flowService } from './PrintNode.flow.index';

import { $frontendTypes } from './PrintNode.frontend.types'

@Injectable({ providedIn: 'root' })
export class PrintNode_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public PrintNode: PrintNode_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _print_request_flow: PrintNode_print_request_flowService;
  public async print_request_flow(inParams: { printerId: number, content: string, contentType?: string }): Promise< { outputDetails?: string, printJobId?: number }> {
    if(!this._print_request_flow) {
      this._print_request_flow = this.injector.get(PrintNode_print_request_flowService);
    }
    return this._print_request_flow.run(inParams);
  }
   
   

   
 
  private _validate_credentials_flow: PrintNode_validate_credentials_flowService;
  public async validate_credentials_flow(inParams: {  }): Promise< { validation?: string, outputDetails?: string }> {
    if(!this._validate_credentials_flow) {
      this._validate_credentials_flow = this.injector.get(PrintNode_validate_credentials_flowService);
    }
    return this._validate_credentials_flow.run(inParams);
  }
   
   

   
}
